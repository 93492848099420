<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5> Vendas</h5>
          </div>
          <div class="w-50">
            <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                   type="text" v-model="search">
          </div>


        </div>

      </div>
    </div>
    <div class="table-responsive">
      <div class="card-body">
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th></th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown cursor-pointer">
                <button class="btn btn-system dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                  Ações
                </button>
                <ul class="dropdown-menu cursor-pointer">
                  <li>
                    <span class="dropdown-item " @click="listPerguntas(row.id)">
                      <i class="bi bi-ui-checks"></i>
                      Perguntas
                    </span>
                  </li>

                  <li>
                    <span class="dropdown-item" @click="listAnexoAvulso(row.id)">
                      <i class="bi bi-file-earmark-richtext"></i>
                      Anexar Documentos
                    </span>
                  </li>

                  <li>
                    <span class="dropdown-item " @click="listServicos(row.id)">
                      <i class="bi bi-briefcase-fill"></i>
                      Serviços
                    </span>
                  </li>

                  <li>
                    <a class="dropdown-item" :href="'/vendas/'+row.id+'/edit'">
                      <i class="bi bi-pencil-square"></i>
                      Editar Venda
                    </a>
                  </li>

                  <li>
                       <span class="dropdown-item " @click="abrirChat(row.id)">
                        <i class="bi bi-chat"></i>
                         Mensagem
                      </span>
                  </li>

                  <li>
                    <span class="dropdown-item " @click="deleteRow(row.id)">
                      <i class="bi bi-trash2-fill"></i>
                      Apagar
                    </span>
                  </li>
                  <li>
                    <span class="dropdown-item " @click="desfazerVenda(row.id)">
                      <i class="bi bi-arrow-clockwise"></i>
                      Voltar para Orçamento
                    </span>
                  </li>
                  <li>
                    <span class="dropdown-item " @click="cancelarVenda(row.id)">
                    <i class="bi bi-x-circle"></i>
                      Cancelar Venda
                    </span>
                  </li>
                </ul>
              </div>


            </td>
            <td>
              <div class="row">
                <div class="col-md-4">
                  <strong>
                    {{ row.id }}-
                  </strong>
                  <strong>Nome:</strong>
                  <br>
                  {{ row.contato_nome }}
                </div>
                <div class="col-md-4">
                  <strong>Vendedor:</strong>
                  <br>
                  {{ row.user_name }}
                </div>
                <div class="col-md-4">
                  <strong>Status:</strong>
                  <br>
                  <span v-if="row.status=='novo'" class="badge bg-system">Novo</span>
                  <span v-if="row.status=='finalizado'" class="badge bg-success">Finalizado</span>
                  <span v-if="row.status=='pendente'" class="badge bg-danger">Pendente</span>
                  <span v-if="row.status=='cancelado'" class="badge bg-danger">Cancelado</span>

                </div>
                <div class="col-md-4">
                  <strong>Selecionado:</strong>
                  <br>
                  {{ row.selecionado }}
                </div>
                <div class="col-md-4">
                  <strong>Criado:</strong>
                  <br>
                  {{ row.created }}
                </div>

                <div class="col-md-4">
                  <strong>Seguradora</strong>
                  <br>
                  {{ row.seguradora }}
                </div>
                <div class="col-md-4">
                  <button v-if="row.selecionado" class="btn btn-success" @click="download(row.id,row.seguradora)">
                    <i class="bi bi-cloud-arrow-down"></i>
                    Cotação
                  </button>
                </div>

              </div>
            </td>

          </tr>
          <tr v-if="rows==null && loading == false">
            <td colspan="10" class="text-center"> Não há dados</td>
          </tr>
          <tr v-if="loading == true">

            <td colspan="10">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
    <modal-lateral-wiget v-if="modalChat">
      <div id="offcanvas-body" class="offcanvas-body">
        <div class="row">
          <div class="col-12 pt-5">
            <span @click="fecharChat" class="close">&times;</span>
            <h3>Mensagens</h3>
          </div>
          <div class="col-12">
            <index-chat-vendas v-if="reload" :venda_id="venda_id"></index-chat-vendas>
          </div>
        </div>
      </div>
      <div class="offcanvas-footer">
        <div class="row">
          <div class="col-10">
            <input type="text" class="form-control" id="mensagem">
          </div>
          <div class="col-2">
            <button class="btn btn-light" @click="sendMensagem">
              <i class="bi bi-send-fill"></i>
            </button>

          </div>
        </div>
      </div>
    </modal-lateral-wiget>
    <ModalWidget id="list-servicos">
      <div class="row">
        <div class="col-12">
        </div>
      </div>
      <ListServicos :rows="rowServicos" v-if="rowServicos"></ListServicos>
    </ModalWidget>
    <ModalWidgetVue v-if="modalPerguntas" id="list-perguntas">
      <div class="row">
        <div class="col-12">
          <div class="modal-content">
            <span @click="noVisible" class="close">&times;</span>
            <list-perguntas :venda_id="venda_id"></list-perguntas>
          </div>
        </div>
      </div>


    </ModalWidgetVue>
    <ModalWidgetVue v-if="modalAnexoAvulso" id="list-anexos">
      <div class="row">
        <div class="col-12">
          <div class="modal-content">
            <span @click="noVisible" class="close">&times;</span>
            <index-documentos-avulsos-vendas :venda_id="venda_id"></index-documentos-avulsos-vendas>
          </div>
        </div>
      </div>
    </ModalWidgetVue>
  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";

import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";
import ModalWidget from "@/components/widget/modalWidget.vue";
import ListPerguntas from "@/views/perguntas/ListPerguntas.vue";
import ModalWidgetVue from "@/components/widget/modalWidgetVue.vue";
import IndexDocumentosAvulsosVendas from "@/views/documentos_avulsos_vendas/IndexDocumentosAvulsosVendas.vue";
import ModalLateralWiget from "@/components/widget/modalLateralWiget.vue";
import IndexChatVendas from "@/views/chat_vendas/IndexChatVendas.vue";
import ListServicos from "@/views/vendas/ListServicos.vue";


export default {
  name: "IndexCotations",
  components: {
    ListServicos,
    IndexChatVendas,
    ModalLateralWiget,
    IndexDocumentosAvulsosVendas, ModalWidgetVue, ListPerguntas, ModalWidget, LayoutPage
  },
  data() {
    return {
      rows: null,
      search: null,
      perguntas: null,
      modalPerguntas: false,
      venda_id: null,
      modalAnexos: false,
      modalAnexoAvulso: false,
      modalChat: false,
      reload: true,
      rowServicos: [],
      loading: true
    }
  },
  methods: {

    async deleteRow(id) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas/' + id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error(dataRow.data.message);
      }
    },
    noVisible() {
      this.modalPerguntas = false;
      this.modalAnexos = false;
      this.modalAnexoAvulso = false;
    },
    async list() {

      this.loading = true;
      let dataRequest = {
        tipo: 'venda'
      };
      let requestHelper = new RequestHelper();
      let helpers = new Helpers();

      if (!helpers.empty(this.search)) {
        dataRequest = {
          search: this.search,
          tipo: 'venda'
        };
      }

      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas', dataRequest);
      if (dataRow?.data?.data?.length > 0) {
        this.rows = dataRow.data.data;

      }

      this.loading = false;
    },
    async desfazerVenda(venda_id){
      let requestHelper = new RequestHelper();
      let dataRequest = {
        _method:'PUT'
      };
      await requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas/'+venda_id+'/orcamento/transform', dataRequest);
        location.href = '/cotations/index'
      },
    async cancelarVenda(venda_id){
      let requestHelper = new RequestHelper();
      let dataRequest = {
        _method:'PUT'
      };
      await requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas/'+venda_id+'/cancelar', dataRequest);
      await this.list();
    },
    async listServicos(id) {

      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas_servicos', {venda_id: id});
      if (!new Helpers().empty(dataRow?.data)) {
        console.log(dataRow?.data)
        this.rowServicos = await dataRow.data;
        document.getElementById('list-servicos').classList.remove('d-none');

      }
    },
    async listPerguntas(id) {
      this.venda_id = await id;
      this.modalPerguntas = true;

    },
    async gerarVenda(id) {
      let requestHelper = new RequestHelper();
      requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas/' + id + '/tipoVendaTransform', {"_method": "PUT"});
      location.href = '/vendas/:id/edit'.replace(':id', id)
    },
    async listAnexoAvulso(id) {
      this.venda_id = await id;
      this.modalAnexoAvulso = true;
    },
    async abrirChat(id) {
      this.modalChat = false;
      this.venda_id = await id;
      this.modalChat = true;

    },

    async fecharChat() {
      this.modalChat = false;

    },
    async sendMensagem() {
      let dataForm = {
        venda_id: this.venda_id,
        mensagem: document.getElementById('mensagem').value,


      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/chat_vendas', dataForm);
      if (response.data?.id) {
        this.reload = await false;
        this.reload = await true;
        document.getElementById('mensagem').value = '';
        setTimeout(() => {
          let scroll = document.getElementById("offcanvas-body");
          scroll.scrollTop = scroll.scrollHeight;
        }, 200)

      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    },
    async download(id, fileName) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas/download/' + id,{});
      if (!dataRow.data.id){
        return false;
      }
      let base64Data = dataRow.data.anexo;
      // Obter o tipo MIME a partir dos dados Base64
      if (base64Data == null) {
        toastr.error('Não há cotação');
        return false;
      }
      const mimeType = base64Data.split(':')[1].split(';')[0];

      // Decodificar a string Base64 em um array de bytes
      const byteCharacters = atob(base64Data.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Criar um objeto Blob com os dados decodificados e o tipo MIME
      const blob = new Blob([byteArray], {type: mimeType});

      // Obter a extensão do arquivo a partir do tipo MIME
      const fileExtension = mimeType.split('/')[1];


      // Definir o nome de arquivo como o nome fornecido ou o nome padrão
      const finalFileName = fileName + '.' + fileExtension

      // Criar um URL do objeto Blob
      const blobUrl = URL.createObjectURL(blob);

      // Criar um link de download e acionar o clique nele para iniciar o download
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = finalFileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();

      // Remover o link após o download
      document.body.removeChild(a);

      // Liberar o URL do objeto Blob
      URL.revokeObjectURL(blobUrl);
    }


  },
  created() {
    this.list();

  },

}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";

</style>

<style>

:root {
  --display-label-hover: none;
}

.btn-hover:hover {
  --display-label-hover: flex;
  transition-duration: 2s !important;
}

.hover {
  display: var(--display-label-hover) !important;

}
</style>