export default class DataFormat {
   static number_format(number, placeDecimal = 2, simbolDecimals = '.', simbolThousands = '') {
        // Converte o número para uma string
        let numberStr = number.toString();

        // Divide o número em parte inteira e parte decimal
        let [integerPart, decimalPart] = numberStr.split('.');

        // Formata a parte decimal com o número de casas decimais especificado
        if (decimalPart) {
            decimalPart = decimalPart.substring(0, placeDecimal);
        }

        // Adiciona o símbolo de milhar, se especificado
        if (simbolThousands) {
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, simbolThousands);
        }

        // Se a parte decimal não estiver vazia, adiciona o ponto decimal
        if (decimalPart) {
            numberStr = integerPart + simbolDecimals + decimalPart;
        } else {
            numberStr = integerPart;
        }

        return numberStr;
    }
}