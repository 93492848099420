<template>
    <layout-page>
        <div class="card-header">
            <div class="row">
                <div class="col-12 ps-4 pt-3 ">
                    <div class="float-start">
                        <h5> Sessões</h5>
                    </div>
                    <div class="w-50">
                        <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                               type="text" v-model="search">
                    </div>

                    <div class="float-end">
                        <button-widget cor="azul" href="./create" tamanho="M">
                            Adicionar
                        </button-widget>
                    </div>
                  <div class="float-end">
                    <button-widget class="me-3" cor="azul" :href="'/home/'+business_id" tamanho="M">
                      Ir para o site
                    </button-widget>
                  </div>
                </div>

            </div>
        </div>

        <div class="card-body">
            <table class="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>Imagem/Video</th>
                    <th>Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="row in rows" :key="row.id">
                    <td :class="row.parent_id!==null?'ps-4':''">
                        <i v-if="row.parent_id==null" class="bi bi-caret-right-fill"></i>
                        {{ row.id }}
                    </td>
                    <td>{{ row.nome }}</td>
                    <td><img style="height: 50px" :src="row.image_video"></td>


                    <td>
                        <div class="btn-group btn-sm" role="group" aria-label="Basic example">
                            <router-link class="btn btn-system" :to="'./'+row.id+'/edit'">
                                <i class="bi bi-pencil-square"></i>
                            </router-link>
                            <button class="btn btn-system" @click="deleteRow(row.id)">
                                <i class="bi bi-trash2-fill"></i>
                            </button>
                        </div>

                    </td>
                </tr>
                <tr v-if="rows==null && loading == false">
                  <td colspan="10" class="text-center"> Não há dados</td>
                </tr>
                <tr v-if="loading == true">

                  <td colspan="10">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <div class="loader"></div>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
            </table>

        </div>

    </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";

export default {
    name: "IndexSessions",
    components: {ButtonWidget, LayoutPage},
    data() {
        return {
            rows: null,
            search: null,
            business_id:0,
            loading:true
        }
    },
    methods: {
        async list() {
            this.loading = true;
            let dataRequest = {};
            let requestHelper = new RequestHelper();
            let helpers = new Helpers();
          let responseRequest = await requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/auth/me', {view: true});
         this.business_id = await btoa(responseRequest.data.business_id);
            if (!helpers.empty(this.search)) {
                dataRequest = {
                    search: this.search
                };
            }

            let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/sessions', dataRequest);

            if (dataRow.data.data.length > 0) {
                this.rows = dataRow.data.data;

            } else if (!helpers.empty(dataRow.response?.data)) {
                toastr.error('Houve um problema');
            }
          this.loading = false;

        },
        async deleteRow(id) {
            let requestHelper = new RequestHelper();
            let dataRow = await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/sessions/' + id);
            if (dataRow.data.success) {
                this.list();
                toastr.success('Apagado com sucesso');
            } else {
                toastr.error('Houve um problema ao apagar');
            }
        }

    },
    created() {
        this.list();

    }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>