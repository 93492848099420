<script>
export default {
  name: "ListServicos",
  props: {
    rows: Object
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <h2>Serviços</h2>
    </div>
    <div class="col-12">
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>Nome</th>
          <th>Categoria</th>
          <th>Franquiadora</th>
          <th>Comissão Vendedor</th>
          <th>Corretora</th>
          <th>Valor Prêmio</th>
          <th>Desconto</th>
        </tr>

        </thead>
        <tbody>
        <tr v-for="row in rows" v-bind:key="row">
          <td> {{ row.servico_id }}</td>
          <td> {{ new String(row.servico_nome).replace('.',',') }}</td>
          <td> {{ new String(row.categoria_nome).replace('.',',')  }}</td>
          <td>R$ {{ new String(row.valor_comissao_franqueadora).replace('.',',')  }}</td>
          <td>R$ {{ new String(row.valor_comissao_vendedor).replace('.',',')  }}</td>
          <td>R$ {{ new String(row.valor_comissao_corretora).replace('.',',')  }}</td>
          <td>R$ {{ new String(row.valor_premio).replace('.',',')  }}</td>
          <td>R$ {{ new String(row.valor_desconto).replace('.',',')  }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>

</style>