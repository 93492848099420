<template>
    <div>
        <loading-page></loading-page>
        <router-view></router-view>
    </div>

</template>

<script>
import LoadingPage from "@/components/page/loadingPage.vue";

export default {
    name: 'App',
    components: {LoadingPage},

}
</script>
<style>
.cursor-pointer{
  cursor: pointer;
}
.toast-error {
    background-color: red !important;
}

.toast-success {
    background-color: green !important;
}

.toast-info {
    background-color: dodgerblue !important;
}
html{
    background-color: #3baaa1;
    height: 100% !important;
}
.btn-action-list,.btn-system,.bg-system{
  background-color: #15233C !important;
  color: white !important;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: fit-content;

  font-family: monospace;

  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Carregando..."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}


</style>