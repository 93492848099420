<template>
  <input-form class-list="col-12" type="string" label="Nome " value="" name="name"/>
  <input-form class-list="col-12" type="string" label="Email " value="" name="email"/>
  <input-form class-list="col-12" type="timestamp" label="Email VerifIEd At " value="" name="email_verified_at"/>
  <input-form class-list="col-12" type="string" label="Senha" value="" name="password"/>
  <input-form class-list="col-12" type="select"
              :items="[
                  {id:'admin',message:'Administrador',},
                  {id:'franqueador',message:'Franqueador',},
                  {id:'revendedor',message:'Cotador',},
                  {id:'vendedor',message:'Vendedor',},
                  {id:'seguradora',message:'Seguradora',}
                  ]"
              label="Tipo " value="" name="type"/>

  <input-form v-if="tipoUser==='admin'" type="select2" url="/api/users/list" label="Franquiadora"
              name="franquiadora_user_id"></input-form>
  <input-form v-if="tipoUser==='franqueador'" type="hidden" name="franquiadora_user_id"
              :value="this.userId"></input-form>

  <input-form class-list="col-12" type="string" label="Telefone 1" value="" name="telefone_um"/>
  <input-form class-list="col-12" type="string" label="Telefone 2" value="" name="telefone_dois"/>
  <input-form class-list="col-12" type="select" label="Tipo Chave"
              :items="[
                  {id:'email',message:'Email',},
                  {id:'telefone',message:'Telefone',},
                  {id:'cpf_cnpj',message:'CPF/CNPJ',},
                  {id:'aleatorio',message:'Chave Aleatória',},

                  ]" value="" name="tipo_chave_pix"/>
  <input-form class-list="col-12" type="string" label="Chave"

              value="" name="chave_pix"/>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";
import RequestHelper from "@/services/RequestHelper";


export default {
  name: "FormUsers",
  components: {InputForm},
  data() {
    return {
      users: null,
      userId: null,
      tipoUser: null
    }
  },
  methods: {
    async getUsers() {
      let request = new RequestHelper();
      let resposeRequest = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/auth/me', {view: true});
      this.tipoUser = await resposeRequest.data.type;
      if (this.tipoUser == 'franqueador') {

        this.userId = await resposeRequest.data.id;
      } else {
        this.users = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/users/list', {});
        this.users = await this.users.data;
      }
      console.log(this.tipoUser)

    },


  },
  created() {
    this.getUsers();
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>