<script>
import axios from "axios";
import Swal from "sweetalert2";
import LoadingPage from "@/components/page/loadingPage.vue";

export default {
  name: "IntegrationLogin",
  components: {LoadingPage},
  mounted() {
    let hash = this.$route.params.hash;
    axios.post(process.env.VUE_APP_API_HOST_NAME + '/api/auth/me', {}, {
      headers: {
        Authorization: `Bearer ${hash}`,
        Accept: 'application/json',
        "Content-Type": 'application/json'
      }
    }).then((response) => {
      if (response.data.id) {
        localStorage.setItem('HASH', hash)
        location.href='/painel'
        return true
      }

      this.notfyErro()

    }).catch(() => {
      this.notfyErro()
    })


  },
  methods: {
    notfyErro() {
      Swal.fire('Oops',
          'Não Foi possivel validar esse usuário',
          'error',).then(() => {
        history.back();
      });
      this.loader = false;
    }
  },
  data() {
    return {
      loader: true
    }
  }
}
</script>

<template>
  <loading-page v-if="loader"></loading-page>
</template>

<style scoped>

</style>