<template>


  <div class="row">
    <div class="col-12   vendedor_d_none">
      <a :href="'/anexos_vendas/create/'+venda_id" class="btn btn-success btn-md float-end">

        <i class="bi bi-plus "></i>
        Adicionar
      </a>
      <h4>Propostas - {{ venda_id }} - {{contato_nome}}</h4>
    </div>
    <div class="col-12">
      <hr>
    </div>
    <div v-for="anexo in anexos" v-bind:key="anexo" class="col-12">
      <div class="row">
        <div class="col-12">
          <strong>
            <i class="bi bi-file-earmark-bar-graph-fill" style="height: 100px"></i>
            {{ anexo.nome }}
          </strong>

        </div>
        <div class="col-6 p-4">
          <small class=" vendedor_d_none ">Comissão Total:
            <strong> R$ {{
                calculaPorcentagem(anexo.valor_premio, parseFloat(anexo.porcentagem_corretora) + parseFloat(anexo.porcentagem_franquiadora) + parseFloat(anexo.porcentagem_vendedor))
              }}</strong>
          </small>
          <br>
          <small class=" vendedor_d_none">
            Taxa Franquiadora: <strong> R$ {{
              calculaPorcentagem(anexo.valor_premio, anexo.porcentagem_franquiadora)
            }}</strong>
          </small>
          <br>
          <small class=""
          >Comissão Vendedor: <strong> R$ {{
              calculaPorcentagem(anexo.valor_premio, anexo.porcentagem_vendedor)
            }}</strong></small>
          <br>


        </div>
        <div class="col-6 ">

          <small class=""
          >Valor Prêmio:<strong> R$ {{ calculaPorcentagem(anexo.valor_premio, 100) }}</strong>
            {{ anexo.melhor_opcao ? '(Recomendado)' : '' }}
          </small>
          <br>
          <small>Seguradora: <strong>{{ truncateText(anexo.seguradora) }}</strong></small>
          <br>
          <small class=" vendedor_d_none"
          >Corretora: <strong> R$ {{
              calculaPorcentagem(anexo.valor_premio, anexo.porcentagem_corretora)
            }}</strong></small>
          <br>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-2">
              <button style="--bs-btn-padding-y: 3px; --bs-btn-padding-x: .5rem; font-size: 13px"
                      @click="downloadBase64(anexo.anexo,anexo.nome)" class="btn btn-system">
                Baixar
              </button>
            </div>
            <div class="col-2 vendedor_d_none vendedor_d_none revendedor_d_none">
              <a :href="'/anexos_vendas/'+anexo.id+'/edit'"
                 style="--bs-btn-padding-y: 3px; --bs-btn-padding-x: .5rem; font-size: 13px"
                 class="btn btn-system">
                Editar
              </a>
            </div>
            <div class="col-2">
              <button @click="deleteCotations(anexo.id)" class="btn btn-system vendedor_d_none "
                      style="--bs-btn-padding-y: 3px; --bs-btn-padding-x: .5rem; font-size: 13px">Excluir
              </button>
            </div>
            <div class="col-2">
              <button :class="anexo.selecionado==1?'btn-success':'btn-system'" @click="selectCotations(anexo.id)"
                      class="btn  ms-1"
                      style="--bs-btn-padding-y: 3px; --bs-btn-padding-x: .5rem; font-size: 13px">
                {{ !anexo.selecionado ? 'Selecionar' : 'Selecionado' }}
              </button>
            </div>
            <div class="col-2">
              <button style="--bs-btn-padding-y: 1px; --bs-btn-padding-x: .5rem; font-size: 13px; border-radius: 10px"
                      type="button" v-if="anexo.descritivo!==null || anexo.descritivo!==''" class="btn btn-info m-1"
                      @click="popover(anexo.id)">
                Detalhes
              </button>
              <div :id="'customPopover_'+anexo.id" class="custom-popover d-none">

                <div class="popover-body">{{ anexo.descritivo }}</div>
              </div>
            </div>
            <div class="col-2"></div>
          </div>
        </div>
        <div class="col-12">
          <hr>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";

export default {
  name: "ListAnexos",
  props: {
    contato_nome: String,
    venda_id: String
  },
  methods: {
    popover(id) {
      let popover = document.getElementById('customPopover_' + id);
      if (popover.classList.contains('d-none')) {
        document.getElementById('customPopover_' + id).classList.remove('d-none');
      } else {
        document.getElementById('customPopover_' + id).classList.add('d-none');
      }

    },
    downloadBase64(base64Data, fileName) {
      // Obter o tipo MIME a partir dos dados Base64
      const mimeType = base64Data.split(':')[1].split(';')[0];

      // Decodificar a string Base64 em um array de bytes
      const byteCharacters = atob(base64Data.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Criar um objeto Blob com os dados decodificados e o tipo MIME
      const blob = new Blob([byteArray], {type: mimeType});

      // Obter a extensão do arquivo a partir do tipo MIME
      const fileExtension = mimeType.split('/')[1];


      // Definir o nome de arquivo como o nome fornecido ou o nome padrão
      const finalFileName = fileName + '.' + fileExtension

      // Criar um URL do objeto Blob
      const blobUrl = URL.createObjectURL(blob);

      // Criar um link de download e acionar o clique nele para iniciar o download
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = finalFileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();

      // Remover o link após o download
      document.body.removeChild(a);

      // Liberar o URL do objeto Blob
      URL.revokeObjectURL(blobUrl);
    },
    async deleteCotations(id) {
      let request = new RequestHelper();
      await request.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/anexos_vendas/' + id)
      this.list()
    },
    async list() {
      let request = new RequestHelper();
      let rows = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/anexos_vendas', {
        id: this.venda_id,
        selecionado: 0
      })
      this.anexos = await rows.data
      if (this.anexos?.length == 0) {
        this.anexos = false;
      }
    },
    async selectCotations(id) {
      let request = new RequestHelper();
      await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/anexos_vendas/selecionar/' + id, {
        "_method": "PUT",
        selecionado: true
      })
      this.list()
    },
    calculaPorcentagem(valor, porcentagem) {
      let total = (porcentagem / 100) * valor;
      return new String(total.toFixed(2)).replace('.', ',');
    },
    truncateText(text) {
      if (!new Helpers().empty(text)) {
        if (text.length > 14)
          return text.substr(0, 14) + '...';
      }
      return text;
    }
  },
  data() {
    return {
      anexos: null
    }
  },
  mounted() {
    this.list();

  }
}
</script>

<style scoped>
.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
  font-size: 40px;

  padding: 4px;
}


.custom-popover {

  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 9999;

}

.popover-header {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.popover-body {
  font-size: 13px;
  padding: 2px;

}

</style>