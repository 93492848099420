<template>
  <div class="card-header">
    <div class="row pt-1 pb-5">
      <div class="col-8">
        <input-form type="select2" url="/api/contatos/list" label="Cliente" name="contato_id"></input-form>
      </div>
      <div class="col-4">

        <input-form placeholder="Selecione status" class-list="col-12" type="select"
                    :items="[{id:'novo',message:'Novo',},{id:'pendente',message:'Pendente',},{id:'cancelado',message:'Cancelado',},{id:'finalizado',message:'Finalizado',}]"
                    label="Status"
                    value="novo" name="status"/>
      </div>


    </div>

  </div>
  <div class="card-body panel-producs">


    <div class="row border border-1 ">
      <div class="col-12 ">
        <div class="row border border-1" v-for="servico in servicos" :key="servico">
          <div class="col-4 p-3">
            <strong>Produto:</strong>
            <br>
            <span>
                         {{ servico.servico_id }}-{{ servico.servico_nome }}
                        </span>


          </div>

          <div class="col-6 p-3">
            <div class="row">
              <div class="col-12 p-1">
                <label>Desconto %</label>
                <br>
                <input type="number" id="desconto"
                       :value="servico.porcentagem_desconto">

              </div>
              <div class="col-12 p-1">
                <div class="col-12 p-1">
                  <span class="badge rounded-pill text-bg-primary"
                        style="font-size: 100%">Franquiadora: R$ {{ DataFormat.number_format(servico.valor_comissao_franqueadora,2,',') }}</span>

                </div>
                <div class="col-12 p-1">
                  <span class="badge rounded-pill text-bg-danger" :id="'comissao_vendedor['+servico.servico_id+']'"
                        style="font-size: 100%">Comissão Vendedor: R$ {{ DataFormat.number_format(servico.valor_comissao_vendedor,2,',') }}</span>

                </div>
                <div class="col-12 p-1">
                  <span class="badge rounded-pill text-bg-primary"
                        style="font-size: 100%">Corretora: R$ {{ DataFormat.number_format(servico.valor_comissao_corretora,2,',') }}</span>

                </div>
                <div class="col-12 p-1">
                  <span class="badge rounded-pill text-bg-primary"
                        style="font-size: 100%">Comissão Total: R$ {{ DataFormat.number_format(servico.valor_premio,2,',') }}</span>
                </div>
                <div class="col-12 p-1">
                  <span class="badge rounded-pill text-bg-info" :id="'valor_desconto['+servico.servico_id+']'"
                        style="font-size: 100%">Desconto: R$ {{ DataFormat.number_format(servico.valor_desconto,2,',') }}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div v-if="servicos.length==0" class="row toast-center">
          <div class="col-12 p-5 text-center">
            Não há produtos
          </div>

        </div>

      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <input-form class-list="col--6" type="text" label="Observações" value="" name="descritivo"/>
    </div>

  </div>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";

import Helpers from "@/services/Helpers";
import RequestHelper from "@/services/RequestHelper";
import DataFormat from "@/services/DataFormat";


export default {
  name: "FormVendas",
  computed: {
    DataFormat() {
      return DataFormat
    }
  },
  components: {InputForm},
  props: {
    vendaEdit: JSON,
    selecionado: JSON
  },
  data() {
    return {
      vendas: null,
      servicos: [],
      porcentagemDesconto:0

    }
  },
  methods: {

    getCliente() {
      let helper = new Helpers();
      if (!helper.empty(this.vendaEdit?.data)) {
        this.vendas = this.vendaEdit.data;
        document.getElementById('contato_id').value = this.vendas.contato_id;

      }

    },
    getStatus() {
      let helper = new Helpers();
      if (!helper.empty(this.vendaEdit?.data)) {
        this.vendas = this.vendaEdit.data;
        document.getElementById('status').value = this.vendas.status;

      }


    },
    async getServicos() {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas_servicos', {venda_id: this.vendas.id});
      console.log(dataRow?.data)
      if (dataRow?.data?.length > 0) {
        this.servicos = await dataRow.data;
      }
    },
    getDescritivo() {
      let helper = new Helpers();
      if (!helper.empty(this.vendaEdit?.data)) {
        this.vendas = this.vendaEdit.data;
        document.getElementById('descritivo').value = this.vendas.descritivo;

      }
    },
  },
  mounted() {
    this.getCliente();
    this.getStatus();
    this.getServicos();
    this.getDescritivo();
  },


}
</script>

<style scoped>
@import "toastr/build/toastr.css";

.panel-producs {
  background-color: #cbd5e059;
}

.list-aliquotas {
  list-style: none;
}
</style>