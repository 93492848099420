<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5> Financeiros</h5>
          </div>


        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-6">
              <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                     type="text" v-model="search">
            </div>
            <div class="col-6">
              <div class="float-end">
                <button-widget class="btn-sm" cor="azul" href="./create" tamanho="M">
                  Adicionar
                </button-widget>
                <button-widget class="ms-3 btn-sm" cor="verde" href="/financeiros_comissaos_users/create" tamanho="M">
                  Gerar Comissão
                </button-widget>
              </div>
            </div>
            <div class="col-3">
              <input-form class-list="filter" type="tinyint" label="Vencido?" name="vencido"></input-form>
            </div>
            <div class="col-3">
              <input-form class-list="filter" type="month" label="Mês" name="mes"></input-form>
            </div>
            <div class="col-3">
              <input-form class-list="filter" type="tinyint" label="Pago?" name="pago"></input-form>
            </div>
            <div class="col-3">
              <input-form class-list="filter" type="select"
                          :items="[{id:'todos',message:'Todos'},{id:'receber',message:'Receber'},{id:'pagar',message:'Pagar'}]"
                          label="Tipo" name="tipo"></input-form>
            </div>
            <div class="col-12 pt-2">
              <button @click="resetFilter" class-list="filter" class="btn btn-success btn-sm" type="button">Limpar
                Filtro
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="card-body">
      <table class="table" style="height: 250px; overflow: scroll">

        <tbody>
        <tr v-for="row in rows" :key="row.id">
          <td>
            <div class="btn-group btn-sm" role="group" aria-label="Basic example">
              <router-link class="btn btn-system" :to="'./'+row.id+'/edit'">
                <i class="bi bi-pencil-square"></i>
              </router-link>
              <button class="btn btn-system" @click="deleteRow(row.id)">
                <i class="bi bi-trash2-fill"></i>
              </button>
              <button class="btn btn-system pt-0" @click="payment(row.id)">
                <svg width="22" height="22" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#ffffff"
                     speechify-initial-font-family="__Inter_e66fe9, __Inter_Fallback_e66fe9, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
                     speechify-initial-font-size="14px" stroke="#ffffff">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"
                     speechify-initial-font-family="__Inter_e66fe9, __Inter_Fallback_e66fe9, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
                     speechify-initial-font-size="14px"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"
                     speechify-initial-font-family="__Inter_e66fe9, __Inter_Fallback_e66fe9, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
                     speechify-initial-font-size="14px"></g>
                  <g id="SVGRepo_iconCarrier"
                     speechify-initial-font-family="__Inter_e66fe9, __Inter_Fallback_e66fe9, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
                     speechify-initial-font-size="14px">
                    <path fill="#ffffff"
                          d="M258 21.89c-.5 0-1.2 0-1.8.12-4.6.85-10.1 5.1-13.7 14.81-3.8 9.7-4.6 23.53-1.3 38.34 3.4 14.63 10.4 27.24 18.2 34.94 7.6 7.7 14.5 9.8 19.1 9 4.8-.7 10.1-5.1 13.7-14.7 3.8-9.64 4.8-23.66 1.4-38.35-3.5-14.8-10.4-27.29-18.2-34.94-6.6-6.8-12.7-9.22-17.4-9.22zM373.4 151.4c-11 .3-24.9 3.2-38.4 8.9-15.6 6.8-27.6 15.9-34.2 24.5-6.6 8.3-7.2 14.6-5.1 18.3 2.2 3.7 8.3 7.2 20 7.7 11.7.7 27.5-2.2 43-8.8 15.5-6.7 27.7-15.9 34.3-24.3 6.6-8.3 7.1-14.8 5-18.5-2.1-3.8-8.3-7.1-20-7.5-1.6-.3-3-.3-4.6-.3zm-136.3 92.9c-6.6.1-12.6.9-18 2.3-11.8 3-18.6 8.4-20.8 14.9-2.5 6.5 0 14.3 7.8 22.7 8.2 8.2 21.7 16.1 38.5 20.5 16.7 4.4 32.8 4.3 44.8 1.1 12.1-3.1 18.9-8.6 21.1-15 2.3-6.5 0-14.2-8.1-22.7-7.9-8.2-21.4-16.1-38.2-20.4-9.5-2.5-18.8-3.5-27.1-3.4zm160.7 58.1L336 331.7c4.2.2 14.7.5 14.7.5l6.6 8.7 54.7-28.5-14.2-10zm-54.5.1l-57.4 27.2c5.5.3 18.5.5 23.7.8l49.8-23.6-16.1-4.4zm92.6 10.8l-70.5 37.4 14.5 18.7 74.5-44.6-18.5-11.5zm-278.8 9.1a40.33 40.33 0 0 0-9 1c-71.5 16.5-113.7 17.9-126.2 17.9H18v107.5s11.6-1.7 30.9-1.8c37.3 0 103 6.4 167 43.8 3.4 2.1 10.7 2.9 19.8 2.9 24.3 0 61.2-5.8 69.7-9C391 452.6 494 364.5 494 364.5l-32.5-28.4s-79.8 50.9-89.9 55.8c-91.1 44.7-164.9 16.8-164.9 16.8s119.9 3 158.4-27.3l-22.6-34s-82.8-2.3-112.3-6.2c-15.4-2-48.7-18.8-73.1-18.8z"
                          speechify-initial-font-family="__Inter_e66fe9, __Inter_Fallback_e66fe9, -apple-system, BlinkMacSystemFont, &quot;Segoe UI&quot;, Roboto, Oxygen, Ubuntu, Cantarell, &quot;Fira Sans&quot;, &quot;Droid Sans&quot;, &quot;Helvetica Neue&quot;, sans-serif"
                          speechify-initial-font-size="14px"></path>
                  </g>
                </svg>
              </button>
            </div>

          </td>
          <td>
            <div class="row">
              <div class="col-3 p-2">
                <strong>Nome: </strong>
                <br>
                <span>{{ row.nome }}</span>
              </div>

              <div class="col-3 p-2">
                <strong>Valor: </strong>
                <br>
                R$ <span>{{ String(row.valor).replace('.', ',') }}</span>
              </div>
              <div class="col-3 p-2 ">
                <strong>Pago: </strong>
                <br>
                R$ <span>{{ String(row.valor_total_pago ?? 0.00).replace('.', ',') }}</span>
              </div>
              <div class="col-3 p-2 ">
                <strong>Restante: </strong>
                <br>
                R$ <span>{{ String(row.valor - row.valor_total_pago).replace('.', ',') }}</span>
              </div>
              <div class="col-12 p-2">

                <span style="--bs-btn-padding-y: .05rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                      type="button" v-if="row.tipo=='receber'" class="badge text-bg-success m-2">
                  Receber
                </span>
                <button style="--bs-btn-padding-y: .05rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;"
                        type="button" v-if="row.tipo=='pagar'" class="badge text-bg-danger m-2">
                  Pagar
                </button>
                <span class="badge text-bg-primary m-2"> Parcelas Totais: {{ row.parcela }}</span>
                <span class="badge text-bg-primary m-2"> Pagamentos: {{ row.pagamentos ?? 0 }}</span>
                <span class="badge m-2"
                      :class="row.vencido==0 && (row.valor_total_pago-row.desconto_total)<row.valor ?'text-bg-danger':'text-bg-primary'"> Data de Vencimento: {{
                    row.vencimento_primario ?? 0
                  }}</span>
                <span class="badge text-bg-primary m-2"> Data de Criação: {{ row.created ?? 0 }}</span>
                <span v-if="(row.valor_total_pago-row.desconto_total)>=row.valor" class="badge text-bg-success m-2">  Pago</span>
                <span v-if="(row.valor_total_pago-row.desconto_total)<row.valor" class="badge text-bg-danger m-2"> A pagar</span>
              </div>


            </div>

          </td>


        </tr>
        <tr v-if="rows==null && loading == false">
          <td colspan="10" class="text-center"> Não há dados</td>
        </tr>
        <tr v-if="loading == true">

          <td colspan="10">
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <div class="loader"></div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

    </div>

  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";
import InputForm from "@/components/form/inputForm.vue";

export default {
  name: "IndexFinanceiros",
  components: {InputForm, ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null,
      loading: true
    }
  },
  methods: {
    resetFilter() {
      document.getElementById('vencido').value = '';
      document.getElementById('mes').value = '';
      document.getElementById('pago').value = '';
      document.getElementById('tipo').value = '';
      this.list()
    },
    async filter() {

      let search = {};
      let vencido = document.getElementById('vencido').value;
      let mes = document.getElementById('mes').value;
      let pago = document.getElementById('pago').value;
      let tipo = document.getElementById('tipo').value;
      console.log(!new Helpers().empty(pago))
      if (vencido != '') {
        search.vencido = vencido;
      }

      if (mes != '') {
        search.mes = mes;
      }

      if (pago != '') {

        if (pago == 1) {
          search.pago = 'sim'
        } else {
          search.pago = 'nao'
        }
      }


      if (tipo != '') {
        search.tipo = tipo;
      }
      this.list(search);
    },
    async list(searchs = false) {
      this.loading = true;
      let dataRequest = {};
      if (searchs) {
        if (!new Helpers().empty(searchs)) {
          dataRequest = searchs
        }
      }
      let requestHelper = new RequestHelper();
      let helpers = new Helpers();

      if (!helpers.empty(this.search)) {
        dataRequest.search = this.search
      }

      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros', dataRequest);

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else {
        this.rows = [];
      }
      this.loading = false;

    },
    async deleteRow(id) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros/' + id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    async payment(id) {
      location.href = '/pagamentos/index/' + id
    }

  },
  created() {
    this.list();

  },
  mounted() {
    let filter = document.getElementsByClassName('filter');
    for (let i = 0; i < filter.length; i++) {
      filter[i].addEventListener('change', () => {
        this.filter()
      });
    }
  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>