import {createRouter, createWebHistory} from "vue-router";

import RegisterForm from "@/views/auth/RegisterForm.vue";

import IndexBusiness from "@/views/business/IndexBusiness.vue";
import IndexCategorias from "@/views/categorias/IndexCategorias.vue";
import IndexContatos from "@/views/contatos/IndexContatos.vue";
import DashBoardPage from "@/views/pages/DashBoardPage.vue";
import HomePage from "@/views/pages/HomePage.vue";
import IndexServicos from "@/views/servicos/IndexServicos.vue";
import IndexUsers from "@/views/users/IndexUsers.vue";
import IndexVendas from "@/views/vendas/IndexVendas.vue";
import LoginForm from "@/views/auth/LoginForm.vue";
import notFound from "@/views/errors/NotFound.vue";
import Middleware from "@/services/Middleware";
import CreateCategorias from "@/views/categorias/CreateCategorias.vue";
import EditCategorias from "@/views/categorias/EditCategorias.vue";
import CreateContatos from "@/views/contatos/CreateContatos.vue";
import EditContatos from "@/views/contatos/EditContatos.vue";
import createBusiness from "@/views/business/CreateBusiness.vue";
import EditBusiness from "@/views/business/EditBusiness.vue";
import EditEnderecos from "@/views/enderecos/EditEnderecos.vue";
import CreateServicos from "@/views/servicos/CreateServicos.vue";
import EditServicos from "@/views/servicos/EditServicos.vue";

import CreateUsers from "@/views/users/CreateUsers.vue";
import EditUsers from "@/views/users/EditUsers.vue";
import EditEntrevistas from "@/views/entrevistas/EditEntrevistas.vue";
import EditPerguntas from "@/views/perguntas/EditPerguntas.vue";

import EditVendas from "@/views/vendas/EditVendas.vue";
import FormCaptations from "@/views/cotacaos/FormCaptations.vue";
import IndexCotations from "@/views/cotations/IndexCotations.vue";
import EditSessions from "@/views/sessions/EditSessions.vue";
import CreateSessions from "@/views/sessions/CreateSessions.vue";
import IndexSessions from "@/views/sessions/IndexSessions.vue";
import CreateAnexosVendas from "@/views/anexos_vendas/CreateAnexosVendas.vue";
import indexDocumentosAvulsosVendas from "@/views/documentos_avulsos_vendas/IndexDocumentosAvulsosVendas.vue";
import createDocumentosAvulsosVendas from "@/views/documentos_avulsos_vendas/CreateDocumentosAvulsosVendas.vue";
import editDocumentosAvulsosVendas from "@/views/documentos_avulsos_vendas/EditDocumentosAvulsosVendas.vue";
import IndexFinanceiros from "@/views/financeiros/IndexFinanceiros.vue";
import CreateFinanceiros from "@/views/financeiros/CreateFinanceiros.vue";
import EditFinanceiros from "@/views/financeiros/EditFinanceiros.vue";
import IndexFinanceirosPagamentos from "@/views/financeiros_pagamentos/IndexFinanceirosPagamentos.vue";
import createFinanceirosPagamentos from "@/views/financeiros_pagamentos/CreateFinanceirosPagamentos.vue";
import editFinanceirosPagamentos from "@/views/financeiros_pagamentos/EditFinanceirosPagamentos.vue";
import IndexFinanceirosComissaosUsers from "@/views/financeiros_comissaos_users/IndexFinanceirosComissaosUsers.vue";
import CreateFinanceirosComissaosUsers from "@/views/financeiros_comissaos_users/CreateFinanceirosComissaosUsers.vue";
import IntegrationLogin from "@/views/auth/IntegrationLogin.vue";
import EditAnexosVendas from "@/views/anexos_vendas/EditAnexosVendas.vue";
import ForgetPasswordForm from "@/views/auth/ForgetPasswordForm.vue";


const routes = [

    {
        path: '/login',
        name: 'LoginForm',
        component: LoginForm,

    },
    {
        path: '/integration/login/:hash',
        name: 'Integration',
        component: IntegrationLogin,

    },
    {
        path: '/register',
        name: 'registerForm',
        component: RegisterForm
    },

    {
        path: '/sessoes/index',
        name: 'indexSessions',
        component: IndexSessions,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/sessoes/create',
        name: 'createSessions',
        component: CreateSessions,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/sessoes/:id/edit',
        name: 'editSessions',
        component: EditSessions,
        meta: {
            auth: true,
            authorize: ['admin']

        }
    },

    {
        path: '/business/index',
        name: 'indexBusiness',
        component: IndexBusiness,
        meta: {
            auth: true,
            authorize: ['admin']

        }
    },
    {
        path: '/business/create',
        name: 'createBusiness',
        component: createBusiness,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/business/:id/edit',
        name: 'editBusiness',
        component: EditBusiness,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },

    {
        path: '/categorias/index',
        name: 'indexCategorias',
        component: IndexCategorias,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },

    {
        path: '/categorias/create',
        name: 'createCategorias',
        component: CreateCategorias,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },
    {
        path: '/categorias/:id/edit',
        name: 'editCategorias',
        component: EditCategorias,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },

    {
        path: '/contatos/index',
        name: 'indexContatos',
        component: IndexContatos,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor']
        }
    },
    {
        path: '/contatos/create',
        name: 'createContatos',
        component: CreateContatos,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },
    {
        path: '/contatos/:id/edit',
        name: 'editContatos',
        component: EditContatos,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },
    {
        path: '/enderecos/:id/edit',
        name: 'editEnderecos',
        component: EditEnderecos,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },


    {
        path: '/painel',
        name: 'painel',
        component: DashBoardPage,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor','franqueador']
        }
    },

    {
        path: '/servicos/index',
        name: 'indexServicos',
        component: IndexServicos,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor']

        }
    },
    {
        path: '/servicos/create',
        name: 'createServicos',
        component: CreateServicos,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },
    {
        path: '/servicos/:id/edit',
        name: 'editServicos',
        component: EditServicos,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor']
        }
    },
    {
        path: '/cotations/:id',
        name: 'FormCaptations',
        component: FormCaptations,
        meta: {
            auth: false
        }
    },


    {
        path: '/users/index',
        name: 'indexUsers',
        component: IndexUsers,
        meta: {
            auth: true,
            authorize: ['admin','franqueador']
        }
    },
    {
        path: '/users/create',
        name: 'createUsers',
        component: CreateUsers,
        meta: {
            auth: true,
            authorize: ['admin','franqueador']
        }
    },
    {
        path: '/users/:id/edit',
        name: 'editUsers',
        component: EditUsers,
        meta: {
            auth: true,
            authorize: ['admin','franqueador']
        }
    },
    {
        path: '/entrevistas/:id/edit',
        name: 'editEntrevistas',
        component: EditEntrevistas,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/perguntas/:id/edit',
        name: 'editPerguntas',
        component: EditPerguntas,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/cotations/index',
        name: 'indexCotations',
        component: IndexCotations,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor']
        }
    },
    {
        path: '/vendas/index',
        name: 'indexVendas',
        component: IndexVendas,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor']
        }
    },
    {
        path: '/vendas/:id/edit',
        name: 'editVendas',
        component: EditVendas,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor']
        }
    },
    {
        path: '/home/:hash',
        name: 'home',
        meta: {
            auth: false,

        },
        component: HomePage
    },
    {
        path: '/anexos_vendas/create/:id',
        name: 'createAnexos',
        component: CreateAnexosVendas,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor']
        }
    },
    {
        path: '/anexos_vendas/:id/edit',
        name: 'EditAnexosVendas',
        component: EditAnexosVendas,
        meta: {
            auth: true,
            authorize: ['admin', 'revendedor', 'vendedor']
        }
    },
    {
        path: '/pagamentos/index/:id',
        name: 'IndexFinanceirosPagamentos',
        component: IndexFinanceirosPagamentos,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/pagamentos/create/:id',
        name: 'createFinanceirosPagamentos',
        component: createFinanceirosPagamentos,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/pagamentos/:id/edit',
        name: 'editFinanceirosPagamentos',
        component: editFinanceirosPagamentos,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/financeiros/index',
        name: 'indexFinanceiros',
        component: IndexFinanceiros,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/financeiros/create',
        name: 'createFinanceiro',
        component: CreateFinanceiros,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/financeiros/:id/edit',
        name: 'EditFinanceiros',
        component: EditFinanceiros,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/financeiros_comissaos_users/index',
        name: 'IndexFinanceirosComissaosUsers',
        component: IndexFinanceirosComissaosUsers,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },
    {
        path: '/financeiros_comissaos_users/create',
        name: 'CreateFinanceirosComissaosUsers',
        component: CreateFinanceirosComissaosUsers,
        meta: {
            auth: true,
            authorize: ['admin']
        }
    },


    {
        path: '/404',
        component: notFound
    },
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/documentos_avulsos_vendas/index',
        name: 'indexDocumentosAvulsosVendas',
        component: indexDocumentosAvulsosVendas,
        meta: {
            auth: true
        }
    },
    {
        path: '/documentos_avulsos_vendas/create/:id',
        name: 'createDocumentosAvulsosVendas',
        component: createDocumentosAvulsosVendas,
        meta: {
            auth: true
        }
    },
    {
        path: '/documentos_avulsos_vendas/:id/edit',
        name: 'editDocumentosAvulsosVendas',
        component: editDocumentosAvulsosVendas,
        meta: {
            auth: true
        }
    },
    {
        path: '/esqueci-minha-senha',
        name: 'ForgetPasswordForm',
        component: ForgetPasswordForm,
        meta: {
            auth: false
        }
    },

];
const router = createRouter({history: createWebHistory(), routes});
router.beforeEach((to) => {
    let middleware = new Middleware();
    if (!middleware.logout(to)) {
        middleware.routeExists(to);
    }

    if (middleware.requiredLogin(to)) {
        middleware.validateHash(to);
        middleware.uniqueConfiguration(to);
    }


})
router.afterEach((to) => {
    let middleware = new Middleware();
    if (middleware.requiredLogin(to)) {
        middleware.setRegisterLastRouteBeforeLogin();
        middleware.userPermissions(to);
    }
    middleware.finishLoading();
});
export default router;