<script>
export default {
  name: "modalLateralWiget"
}
</script>

<template>
  <div class="offcanvas offcanvas-end show" tabindex="9999999" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <slot></slot>
  </div>

</template>

<style scoped>

</style>