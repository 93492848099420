<template>
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark navegacao font-family">
    <div class="container">
      <a href="#" class="navbar-brand">
        <img src="@/assets/logo.png" height="50">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#menu" aria-controls="menu"
              aria-expanded="false" aria-label="Menu Colapso">
        <span class="navbar-toggler-icon text-light"></span>
      </button>

      <div id="menu" class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto text-light nav-menu">

          <li class="nav-item ps-3">

            <a href="#" @click="page('inicio')" class="nav-link text-light font-weight-bold">Inicio</a>
          </li>
          <li v-bind:key="categoria" class="nav-item ps-3" v-for="categoria in this.categoriasList">
            <span style="cursor: pointer;" @click="page(categoria.id)"
                  class="nav-link text-light font-weight-bold">{{ categoria.nome }}</span>
          </li>
          <li class="nav-item ps-3">
            <a href="#" @click="page('aboult')" class="nav-link text-light font-weight-bold">Quem Somos</a>
          </li>

        </ul>
      </div>
    </div>
  </nav>
  <main class="font-family">
    <div class="container">
      <div class="row">
        <div v-if="inicio===true" class="col-12">
          <div v-for="sessionsRow in sessionsRows" v-bind:key="sessionsRow">

            <div class="row" v-if="sessionsRow.type==='carrossel'">
              <div class="col-12">
                <div :id="'carrosel_'+sessionsRow.id"
                     class="carousel slide">

                  <div class="carousel-indicators">
                    <button v-for="(sub_sessions, i) in sessionsRow.sub_sessions"
                            v-bind:key="sub_sessions"
                            type="button" :data-bs-target="'#carrosel_'+sessionsRow.id"
                            :data-bs-slide-to="i" class="active" aria-current="true"
                            :aria-label="'Slide'+i"></button>

                  </div>
                  <div class="carousel-inner">
                    <div v-for="(sub_sessions, i) in sessionsRow.sub_sessions"
                         v-bind:key="sub_sessions"
                         class="carousel-item " :data-bs-interval="i+'000'" :class="i==0?'active':''">
                      <a :href="sub_sessions.link">
                        <img :src="sub_sessions.image_video" class="d-block w-100" alt="...">
                      </a>
                    </div>

                  </div>
                  <button class="carousel-control-prev" type="button"
                          :data-bs-target="'#carrosel_'+sessionsRow.id" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button"
                          :data-bs-target="'#carrosel_'+sessionsRow.id" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>

            </div>
            <div class="row" v-if="sessionsRow.type==='panel'">
              <div class="row">
                <div v-if="sessionsRow.image_video==null"
                     class="col-10 offset-md-2 text-center mt-5 color-white">
                  <h1 class="font-weight-400 title-panel ">
                    {{ sessionsRow.nome }}
                  </h1>

                  <p class="text-end p-4 font-descritivo ">
                    {{ sessionsRow.descritivo }}
                  </p>
                </div>
                <div v-if="sessionsRow.image_video!=null"
                     class="col-12">
                  <div class="row p-4">
                    <div class="col-6">
                      <h2 style="color: #101084;font-weight: bold" class="font-weight-400 title-panel ">
                        {{ sessionsRow.nome }}
                      </h2>

                      <p class="text-end p-4 font-descritivo ">
                        {{ sessionsRow.descritivo }}
                      </p>
                    </div>
                    <div class="col-6">
                      <a :href="sessionsRow.link">
                        <img :src="sessionsRow.image_video" class="d-block w-100" alt="...">
                      </a>
                    </div>
                  </div>

                </div>
                <div v-if="sessionsRow.image_video==null" class="col-6">
                  <img :src="sessionsRow.image_video" alt="">
                </div>
              </div>

            </div>

          </div>

        </div>

        <div v-if="aboult===true" class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="row ">
                <div class="col-12 color-white text-center ps-5 pe-5">
                  <div class="row">
                    <div class="col-12 ps-5 pe-5">
                      <div class="row">
                        <div class="col-12 text-center">
                          <i style="transform: scaleX(-1);" class="bi bi-people-fill icon-about"></i>
                          <i style="transform: scaleX(-1);" class="bi bi-people-fill icon-about"></i>
                        </div>
                      </div>
                      <h1 class="color-white text-center">Valores</h1>
                      <p class="ps-5 pe-5">Nossos valores fundamentais incluem inovação, transparência, rapidez,
                        segurança, eficiência e satisfação do cliente. Buscamos constantemente melhorias para oferecer a
                        melhor experiência possível, com foco na confiança e na eficácia para garantir a satisfação de
                        nossos clientes.</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>
          <div class="row bg-light">
            <div class="col-12">
              <div class="row ">
                <div class="col-12 color-white text-center text-bg-success p-5">
                  <div class="row">
                    <div class="col-12 ps-5 pe-5">
                      <div class="row">
                        <div class="col-12 text-center">
                          <i class="bi bi-crosshair icon-about "></i>
                        </div>
                      </div>
                      <h1 class="color-white text-center">Missão</h1>
                      <p class="ps-5 pe-5">Nossa missão é ser a escolha preferida dos clientes ao proporcionar a melhor
                        experiência de compra de seguros. Fazemos isso por meio da inovação e tecnologia, oferecendo
                        soluções seguras e eficientes com atendimento personalizado e digital para garantir
                        tranquilidade e segurança aos nossos clientes.</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>
          <div class="row">
            <div class="col-12">
              <div class="row ">
                <div class="col-12 color-white text-center p-5">
                  <div class="row">
                    <div class="col-12 ps-5 pe-5">
                      <div class="row">
                        <div class="col-12 text-center">
                          <i class="bi bi-eye icon-about"></i>
                        </div>
                      </div>
                      <h1 class="color-white text-center">Visão</h1>
                      <p class="ps-5 pe-5">Nossa visão é ser a corretora de seguros líder no mercado digital, através da
                        constante inovação e do fornecimento de soluções seguras e eficientes para nossos clientes.
                        Queremos ser reconhecidos como uma empresa que prioriza o bem-estar e a tranquilidade dos seus
                        clientes.</p>
                    </div>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
        <div v-if="categoria===true" class="col-12">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-4 p-2" v-for="row in ProdutosList" v-bind:key="row">
                  <div class="card text-center">
                    <div class="card-header card-header-servicos">
                      <h4>{{ row.nome }}</h4>
                    </div>
                    <div class="card-body card-body-servicos">
                      <div class="row">
                        <div class="col-12">
                          {{ row.descritivo }}
                        </div>
                      </div>
                      <div v-if="row.fotos" class="row">
                        <div class="col-12 p-3">
                          <div class="carrosel">
                            <img class="img-carrosel" :class="i==0?'':'d-none'" v-for="(foto,i) in row.fotos"
                                 :src="foto" alt="" v-bind:key="foto">
                          </div>

                        </div>
                      </div>

                    </div>
                    <div class="card-footer card-footer-servicos">
                      <div class="row">
                        <div class="col-12">
                          <a :href="row.link" class="btn btn-light text-primary">Contrate Agora</a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
  <footer id="sticky-footer" class="flex-shrink-0 py-4 bg-dark text-white-50 font-family">
    <div class="container text-center">
      <img src="@/assets/logo.png" height="40">
      <div class="row p-3">
        <div class="col-12">
          <i class="bi bi-telephone-fill"></i>
          (65) 99308-1672
          <br>
          <i class="bi bi-envelope-at-fill"></i>
          comercial@facilitacorretoradeseguros.com.br
        </div>


      </div>

    </div>

  </footer>

  <a target="_blank" href="https://api.whatsapp.com/send/?phone=556235190915&amp;text&amp;type=phone_number&amp;app_absent=0" class="btn btn-lg btn-success btn-lg-square btn-whatsapp" ><i class="bi bi-whatsapp" ></i></a>

</template>

<script>
import axios from "axios";
import Helpers from "@/services/Helpers";

export default {
  name: "HomePage",
  data() {
    return {
      inicio: true,
      categoria: false,
      categoriaRow: null,
      sessionsRows: null,
      categoriasList: null,
      ProdutosList: null,
      aboult: false,
      interval: null
    }
  },
  methods: {
    async page(id) {
      console.log(this.interval)
      clearInterval(this.interval);
      if (id == 'inicio') {
        this.inicio = true;
        this.categoria = false;
        this.aboult = false;
      } else if (id == 'aboult') {
        this.inicio = false;
        this.categoria = false;
        this.aboult = true;
      } else {
        this.inicio = false;
        this.categoria = true;
        this.aboult = false;
        this.listProdutcts(id);
        await this.slieds();

      }
    },
    listCategoria() {
      axios.get(process.env.VUE_APP_API_HOST_NAME + '/api/site/categorias/list/' + this.$route.params.hash).then((response) => {
        this.categoriasList = response.data
      })
    },
    listSessions() {
      axios.get(process.env.VUE_APP_API_HOST_NAME + '/api/site/sessions/list/' + this.$route.params.hash).then((response) => {
        this.sessionsRows = response.data

      })
    },
    listProdutcts(categoria_id) {
      console.log(process.env.VUE_APP_API_HOST_NAME)
      axios.get(process.env.VUE_APP_API_HOST_NAME + '/api/site/produtos/list/' + this.$route.params.hash + '?categoria_id=' + categoria_id).then((response) => {
        this.ProdutosList = response.data
      })
    },
    slieds() {
      setTimeout(()=> {
        let carrosel = document.getElementsByClassName('carrosel');
        for (let i in carrosel) {
          if (!new Helpers().empty(carrosel[i].innerHTML)) {
            let j = 0;
            this.interval = setInterval(() => {
              let imgs = carrosel[i].getElementsByTagName('img');
              for (let k = 0; k < imgs.length; k++) {
                imgs[k].classList.add('d-none');
              }
              imgs[j].classList.remove('d-none');
              j++;
              if (imgs.length == j) {
                j = 0;
              }

            }, 4000);
          }
        }
      }, 100)

    }

  }
  , mounted() {
    this.listCategoria();
    this.listSessions();

  },

}
</script>

<style scoped>
main {
  margin-top: 77px;
}

body {

}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-350 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.color-white {
  color: white;
}

.title-panel {
  font-size: 300%;
}

.font-descritivo {
  font-size: 150%;
}

.font-family {
  font-family: 'Poppins', sans-serif;
}

.icon-about {
  font-size: 100px;
  color: white;
}

.card-body-servicos {
  height: 200px;
  font-weight: 100 !important;
}

.card-header-servicos {
  height: 100px;
  display: flex;
  align-items: center; /* Isso centralizará verticalmente o conteúdo na div */
  justify-content: center;
  border: none;
}

.card-body {
  overflow-y: hidden !important;

}

.img-carrosel {

  max-width: 200px;
  max-height: 140px;
  opacity: 0; /* Comece com a imagem totalmente transparente */
  animation: fadeIn 0.3s ease-in-out forwards; /* Nome da animação, duração, transição e retenção */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Inicialmente, a imagem é transparente */
  }
  to {
    opacity: 1; /* No final da animação, a imagem é completamente visível */
  }
}
main{
  min-height: 700px;
}
.card-footer-servicos{
  border: none;
}
.card{
  border-radius: 0px;
}
.btn-whatsapp{
  position: fixed;
  right: 50px;
  bottom: 100px;
  height: 60px;
  width: 60px;
  padding-top: 13px;
  border-radius: 50px;
  align-content: center;
}
</style>