<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Documentos Avulsos Vendas</h5>
          </div>
          <div class="float-end">
            <button class="btn btn-system" @click="goBack">
              Voltar
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormDocumentosAvulsosVendas></FormDocumentosAvulsosVendas>
        <button class="btn btn-system mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormDocumentosAvulsosVendas from "@/views/documentos_avulsos_vendas/FormDocumentosAvulsosVendas.vue";
import RequestHelper from "@/services/RequestHelper";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";

export default {
  name: "EditDocumentosAvulsosVendas",
  components: {LayoutPage, FormDocumentosAvulsosVendas},
  methods: {
    async edit(id) {
      let request = new RequestHelper();
      let response = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/documentos_avulsos_vendas/' + id, {});
      document.getElementById('anexo').dataset.value = response.data.anexo;
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('descritivo').value = response.data.descritivo;

    },
    goBack(){
      history.back()
    },
    async sendForm() {
      let dataForm = {
        anexo: document.getElementById('anexo').dataset.value,
        nome: document.getElementById('nome').value,
        descritivo: document.getElementById('descritivo').value,


        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/documentos_avulsos_vendas/' + this.$route.params.id, dataForm);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>