<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5> Pagamentos</h5>
          </div>
          <div class="w-50">
            <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                   type="text" v-model="search">
          </div>

          <div class="float-end">
            <button-widget cor="azul" :href="'/pagamentos/create/'+this.$route.params.id" tamanho="M">
              Adicionar
            </button-widget>
            <button-widget cor="branco" href="/financeiros/index" tamanho="M">
              <svg width="25px" height="25px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000" transform="rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#000000" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path><path fill="#000000" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"></path></g></svg>
            </button-widget>
          </div>
        </div>

      </div>
    </div>

    <div class="card-body">
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>Valor</th>
          <th>Desconto</th>
          <th>Titulo</th>
          <th>Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows" :key="row.id">
          <td>{{ row.id }}</td>
          <td>R$ {{ new String(row.valor).replace('.',',') }}</td>
          <td>R$ {{new String(row.desconto).replace('.',',') }}</td>
          <td>{{ row.titulo }}</td>


          <td>
            <div class="btn-group btn-sm" role="group" aria-label="Basic example">
              <router-link class="btn btn-system" :to="'/pagamentos/'+row.id+'/edit'">
                <i class="bi bi-pencil-square"></i>
              </router-link>
              <button class="btn btn-system" @click="deleteRow(row.id)">
                <i class="bi bi-trash2-fill"></i>
              </button>
            </div>

          </td>
        </tr>
        <tr v-if="rows==null">
          <td colspan="5" class="text-center"> Não há dados</td>
        </tr>
        </tbody>
      </table>

    </div>

  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";

export default {
  name: "IndexFinanceirosPagamentos",
  components: {ButtonWidget, LayoutPage},
  data() {
    return {
      rows: null,
      search: null
    }
  },
  methods: {
    async list() {


      let dataRequest = {
        id:this.$route.params.id
      };
      let requestHelper = new RequestHelper();
      let helpers = new Helpers();

      if (!helpers.empty(this.search)) {
        dataRequest = {
          search: this.search,
          id:this.$route.params.id
        };
      }

      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros_pagamentos', dataRequest);

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      }


    },
    async deleteRow(id) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros_pagamentos/' + id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    }

  },
  created() {
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>