<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Adicionar Pagamentos</h5>
            <span class="badge text-bg-success">Valor devido: R$ {{new String(valorMaximo).replace('.',',')}}</span>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="./index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormFinanceirosPagamentos></FormFinanceirosPagamentos>
        <button class="btn btn-system mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormFinanceirosPagamentos from "@/views/financeiros_pagamentos/FormFinanceirosPagamentos.vue";
import RequestHelper from "@/services/RequestHelper";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateFinanceirosPagamentos",
  components: {FormFinanceirosPagamentos, LayoutPage, ButtonWidget},
  data(){
    return {
      valorMaximo:0
    }
  },

  methods: {
    async sendForm() {
      let dataForm = {
        valor: document.getElementById('valor').value,
        desconto: document.getElementById('desconto').value,
        titulo: document.getElementById('titulo').value,
        financeiro_id: this.$route.params.id,


      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros_pagamentos', dataForm);
      if (response.data?.id) {
        location.href = '/pagamentos/' + response.data.id + '/edit';
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  async mounted() {
    let request = new RequestHelper();
    let response = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/devido/'+this.$route.params.id, {});
    this.valorMaximo = response.data.valor_maximo;
    let displayValor = document.getElementById('display-valor');
    displayValor.addEventListener('change',()=>{
      let valor_pagar = parseFloat(displayValor.value);
      if(valor_pagar>this.valorMaximo){
        displayValor.value = 0;
        toastr.error('Pagamento fornecido é maior que o devido')
      }
    })

  }
}
</script>
<style scoped>
</style>