<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5> Cotações</h5>
          </div>
          <div class="w-50">
            <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
                   type="text" v-model="search">
          </div>


        </div>

      </div>
    </div>
    <div class="table-responsive">
      <div class="card-body">
        <table class="table">
          <thead>
          <tr>
            <th>Ações</th>
            <th>#</th>
            <th>Nome</th>
            <th>Vendedor</th>
            <th>Status</th>
            <th>Selecionado</th>
            <th>Criado</th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="row in rows" :key="row.id">
            <td>
              <div class="dropdown cursor-pointer">
                <button class="btn btn-system dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                 Ações
                </button>
                <ul class="dropdown-menu">
                  <li>
                     <span class="dropdown-item" @click="listPerguntas(row.id)">
                     <i class="bi bi-ui-checks"></i>
                  Perguntas
                  </span>
                  </li>
                <li>
                  <span class="dropdown-item " @click="listAnexoAvulso(row.id)">
                    <i class="bi bi-file-earmark-richtext"></i>
                        Anexar Documentos
                  </span>
                </li>
                <li>
                   <span class="dropdown-item" @click="listServicos(row.id)">
                    <i class="bi bi-briefcase-fill"></i>
                      Serviços
                  </span>
                </li>
                <li>
                   <span  v-if="row.selecionado=='Sim'" class="dropdown-item" @click="gerarVenda(row.id)">

                       <i class="bi bi-shop"></i>
                     Gerar Venda

                  </span>
                </li>
                <li>
                   <span class="dropdown-item " @click="listAnexo(row.id, row.contato_nome)">
                       <i class="bi bi-paperclip"></i>
                      {{isAdminUser?'Anexar cotações':'Cotações anexadas'}}
                  </span>
                </li>
                  <li>
                   <span class="dropdown-item" @click="abrirChat(row.id)">

                       <i class="bi bi-chat"></i>
                      Mensagem
                  </span>
                  </li>
                </ul>

              </div>

            </td>
            <td>{{ row.id }}</td>
            <td>{{ row.contato_nome }}</td>
            <td>{{ row.user_name }}</td>
            <td>{{ row.status }}</td>
            <td>{{ row.selecionado }}</td>
            <td>{{ row.created }}</td>

          </tr>
          <tr v-if="rows==null && loading == false">
            <td colspan="10" class="text-center"> Não há dados</td>
          </tr>
          <tr v-if="loading == true">

            <td colspan="10">
              <div class="row">
                <div class="col-12 d-flex justify-content-center">
                  <div class="loader"></div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
    <ModalWidget id="list-servicos">
      <div class="row">
        <div class="col-12">
          <h2>Serviços</h2>
        </div>
        <div class="col-12">
          <table class="table">
            <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Categoria</th>
            </tr>

            </thead>
            <tbody id="servicos">

            </tbody>
          </table>
        </div>
      </div>

    </ModalWidget>
    <ModalWidgetVue v-if="modalPerguntas" id="list-perguntas">
      <div class="row">
        <div class="col-12">
          <div class="modal-content">
            <span @click="noVisible" class="close">&times;</span>
            <list-perguntas :venda_id="venda_id"></list-perguntas>
          </div>
        </div>
      </div>


    </ModalWidgetVue>
    <ModalWidgetVue v-if="modalAnexos">
      <div class="row">
        <div class="col-12">
          <div class="modal-content">
            <span @click="noVisible" class="close">&times;</span>
            <list-anexos :contato_nome="contato_nome" :venda_id="venda_id"></list-anexos>
          </div>
        </div>
      </div>


    </ModalWidgetVue>
    <ModalWidgetVue v-if="modalAnexoAvulso" id="list-anexos">
      <div class="row">
        <div class="col-12">
          <div class="modal-content">
            <span @click="noVisible" class="close">&times;</span>
            <index-documentos-avulsos-vendas  :venda_id="venda_id"></index-documentos-avulsos-vendas>
          </div>
        </div>
      </div>
    </ModalWidgetVue>
    <modal-lateral-wiget v-if="modalChat">
      <div id="offcanvas-body" class="offcanvas-body">
        <div class="row">
          <div class="col-12 pt-5">
            <span @click="fecharChat" class="close">&times;</span>
            <h3>Mensagens</h3>
          </div>
          <div class="col-12">
            <index-chat-vendas v-if="reload" :venda_id="venda_id"></index-chat-vendas>
          </div>
        </div>
      </div>
      <div class="offcanvas-footer">
        <div class="row">
          <div class="col-10">
            <input type="text" class="form-control" id="mensagem">
          </div>
          <div class="col-2">
            <button class="btn btn-light" @click="sendMensagem">
              <i class="bi bi-send-fill"></i>
            </button>

          </div>
        </div>
      </div>
    </modal-lateral-wiget>
  </layout-page>
</template>
<script>
import LayoutPage from "@/components/page/layoutPage.vue";

import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";
import ModalWidget from "@/components/widget/modalWidget.vue";
import ListPerguntas from "@/views/perguntas/ListPerguntas.vue";
import ModalWidgetVue from "@/components/widget/modalWidgetVue.vue";
import ListAnexos from "@/views/perguntas/ListAnexos.vue";
import IndexDocumentosAvulsosVendas from "@/views/documentos_avulsos_vendas/IndexDocumentosAvulsosVendas.vue";
import ModalLateralWiget from "@/components/widget/modalLateralWiget.vue";
import IndexChatVendas from "@/views/chat_vendas/IndexChatVendas.vue";


export default {
  name: "IndexCotations",
  components: {
    IndexChatVendas,
    ModalLateralWiget,
    IndexDocumentosAvulsosVendas, ListAnexos, ModalWidgetVue, ListPerguntas, ModalWidget, LayoutPage
  },
  data() {
    return {
      rows: null,
      isAdminUser: false,
      search: null,
      perguntas: null,
      modalPerguntas: false,
      venda_id: null,
      modalAnexos: false,
      modalAnexoAvulso: false,
      modalChat: false,
      reload: true,
      contato_nome: '',
      loading: true
    }
  },
  methods: {
    noVisible() {
      this.modalPerguntas = false;
      this.modalAnexos = false;
      this.modalAnexoAvulso = false;
    },
    async userIsAdmin() {
      let request = new RequestHelper();
      let resposeRequest = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/auth/me', {view: true});
      let type_user = await resposeRequest.data.type;
      if(type_user==='admin'){
        this.isAdminUser = true
      }
    },
    async list() {

      this.loading = true;
      let dataRequest = {
        tipo: 'orcamento'
      };
      let requestHelper = new RequestHelper();
      let helpers = new Helpers();

      if (!helpers.empty(this.search)) {
        dataRequest = {
          search: this.search,
          tipo: 'orcamento'
        };
      }

      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas', dataRequest);
      if (dataRow?.data?.data?.length > 0) {
        this.rows = dataRow.data.data;

      }

      this.loading = false;
    },
    async listServicos(id) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/vendas_servicos', {venda_id: id});
      if (!new Helpers().empty(dataRow?.data)) {
        let html = '';

        for (const i in dataRow.data) {
          html += '<tr>' +
              '<td>' + dataRow.data[i].id + '</td>' +
              '<td>' + dataRow.data[i].servico_nome + '</td>' +
              '<td>' + dataRow.data[i].categoria_nome + '</td>' +
              '</tr>';
        }
        document.getElementById('servicos').innerHTML = html;

        document.getElementById('list-servicos').classList.remove('d-none');

      } else {
        toastr.error('Houve um problema ao mostrar os serviços prestados');
      }
    },
    async listPerguntas(id) {
      this.venda_id = await id;
      this.modalPerguntas = true;

    },
    async gerarVenda(id) {

      location.href = '/vendas/:id/edit'.replace(':id', id)
    },
    async listAnexo(id,contato_nome) {
      this.contato_nome = contato_nome;
      this.venda_id = await id;
      this.modalAnexos = true;
    },
    async listAnexoAvulso(id) {
      this.venda_id = await id;
      this.modalAnexoAvulso = true;
    },
    async abrirChat(id) {
      this.modalChat = false;
      this.venda_id = await id;
      this.modalChat = true;

    },

    async fecharChat() {
      this.modalChat = false;

    },
    async sendMensagem() {
      let dataForm = {
        venda_id: this.venda_id,
        mensagem: document.getElementById('mensagem').value,


      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/chat_vendas', dataForm);
      if (response.data?.id) {
        this.reload = await false;
        this.reload = await true;
        document.getElementById('mensagem').value = '';
        setTimeout(() => {
          let scroll = document.getElementById("offcanvas-body");
          scroll.scrollTop = scroll.scrollHeight;
        }, 200)

      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }

  },
  created() {
    this.list();
    this.userIsAdmin();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";

button:hover {

}
</style>