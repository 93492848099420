import RequestHelper from "@/services/RequestHelper";

export default class Comissaos {
    constructor() {
        this.request = new RequestHelper();
        this.listQuantidadeVendasMes = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.listTotalVendasMes = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.valorMesAtual = 0;
    }

    async painelSystem() {

        let responseRequest = await this.request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/panel', {});
        if (responseRequest?.data) {
            let month = await  new Date().getMonth()+1;

            this.listQuantidadeVendasMes = await responseRequest.data.mesQuantidade;
            this.valorMesAtual = await this.listTotalVendasMes[month];
            this.listTotalVendasMes = await responseRequest.data.mesValor;
        }

        return {
            quantidadeTotalVendaMes: this.listQuantidadeVendasMes,
            valorMesAtual: this.valorMesAtual,
            valorTotalVendasMes: this.listTotalVendasMes
        };
    }

    calculateCommission(valor_premio, porcentagem_comissao) {
        let valor =  (valor_premio * porcentagem_comissao / 100);
        return valor;


    }

    async pesquisarLucroUsuario(data_inicio,data_final) {
        let responseRequest = await this.request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/usuario/lucro', {data_inicio:data_inicio,data_final:data_final});
        if ( await responseRequest?.data) {
           return await responseRequest.data.lucroUsuario;
        }
        return await 0;
    }
}