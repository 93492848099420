<template>
  <div class="row">
    <div class="card">
      <div class="col-12">
        <div class="card-body text-center">
          <img src="@/assets/logo.png" width="250">
        </div>
      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-10 offset-md-3 offset-sm-1">
      <div class="card header-page">
        <div class="card-body">
          <div class="row">
            <div class="col-1">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                   width="60.317" height="78.802" viewBox="0 0 49.317 49.802">
                <defs>
                  <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                  gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#bbb971"/>
                    <stop offset="1" stop-color="#1a8c7c"/>
                  </linearGradient>
                  <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1"
                                  gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#c3ba70"/>
                    <stop offset="1" stop-color="#1a8c7c"/>
                  </linearGradient>
                </defs>
                <g id="house" transform="translate(0 -12.654)">
                  <path id="Caminho_4022" data-name="Caminho 4022" d="M0,0H38.953V30.232H0Z"
                        transform="translate(5.135 27.77)" fill="url(#linear-gradient)"/>
                  <rect id="Retângulo_2140" data-name="Retângulo 2140" width="38.953" height="5.233"
                        transform="translate(5.135 27.77)" fill="#127769"/>
                  <path id="Caminho_4014" data-name="Caminho 4014"
                        d="M77.465,183.3H38.512a1.373,1.373,0,0,1-1.454-1.454V151.616a1.373,1.373,0,0,1,1.454-1.454H77.465a1.373,1.373,0,0,1,1.454,1.454v30.232A1.457,1.457,0,0,1,77.465,183.3Zm-37.5-2.907H76.011V153.069H39.965Z"
                        transform="translate(-33.376 -123.845)"/>
                  <path id="Caminho_4015" data-name="Caminho 4015"
                        d="M57.19,42.834H17.074a3.159,3.159,0,0,1-3.052-3.924l2.616-9.3a2.94,2.94,0,0,1,2.907-2.326H54.573a3.1,3.1,0,0,1,3.052,2.326l2.616,9.3A3.092,3.092,0,0,1,57.19,42.834Z"
                        transform="translate(-12.52 -13.175)" fill="url(#linear-gradient-2)"/>
                  <path id="Caminho_4016" data-name="Caminho 4016"
                        d="M44.67,31.113H4.554A4.521,4.521,0,0,1,.194,25.3L2.81,16a4.5,4.5,0,0,1,4.36-3.343H42.2A4.374,4.374,0,0,1,46.56,16l2.616,9.3A4.677,4.677,0,0,1,44.67,31.113ZM7.025,15.561a1.575,1.575,0,0,0-1.6,1.163l-2.616,9.3a1.706,1.706,0,0,0,1.6,2.18H44.67a1.658,1.658,0,0,0,1.6-2.18l-2.616-9.3a1.575,1.575,0,0,0-1.6-1.163Z"
                        transform="translate(0 0)"/>
                  <path id="Caminho_4017" data-name="Caminho 4017"
                        d="M110.465,290.6a4.683,4.683,0,0,0-4.651,4.651v12.936h9.448V295.248A4.812,4.812,0,0,0,110.465,290.6Z"
                        transform="translate(-95.301 -250.327)" fill="#697b8c"/>
                  <path id="Caminho_4018" data-name="Caminho 4018"
                        d="M102.085,297.925H92.638a1.373,1.373,0,0,1-1.454-1.453V283.536a6.1,6.1,0,1,1,12.209,0v12.936A1.336,1.336,0,0,1,102.085,297.925Zm-7.994-2.907h6.541V283.536a3.2,3.2,0,1,0-6.4,0v11.482Z"
                        transform="translate(-82.124 -238.469)"/>
                  <rect id="Retângulo_2141" data-name="Retângulo 2141" width="12.936" height="12.645"
                        transform="translate(25.484 38.962)" fill="#c3c9d1"/>
                  <path id="Caminho_4019" data-name="Caminho 4019"
                        d="M256.248,278.354H243.312a1.373,1.373,0,0,1-1.454-1.454V264.256a1.373,1.373,0,0,1,1.454-1.454h12.936a1.373,1.373,0,0,1,1.454,1.454V276.9A1.456,1.456,0,0,1,256.248,278.354Zm-11.483-2.907h10.029v-9.738H244.765Z"
                        transform="translate(-217.827 -225.294)"/>
                  <path id="Caminho_4020" data-name="Caminho 4020"
                        d="M309.141,278.354a1.373,1.373,0,0,1-1.454-1.454V264.256a1.454,1.454,0,1,1,2.907,0V276.9A1.563,1.563,0,0,1,309.141,278.354Z"
                        transform="translate(-277.116 -225.294)"/>
                  <path id="Caminho_4021" data-name="Caminho 4021"
                        d="M56.366,457.343H12.181a1.453,1.453,0,1,1,0-2.907H56.366a1.373,1.373,0,0,1,1.454,1.454A1.457,1.457,0,0,1,56.366,457.343Z"
                        transform="translate(-9.661 -397.887)"/>
                </g>
              </svg>
            </div>
            <div class=" col-sm-12 ps-5">
              <strong>Consultor</strong>
              <h1 class="name-consultor" v-if="nameConsultor">
                {{ nameConsultor }}
              </h1>
            </div>
            <div class="col-sm-12 ps-5">
              <h2 class="name-produto" v-if="nameProduto">{{ nameProduto }}</h2>
            </div>
            <div class="col-sm-12 px-5 py-2">
              <p class="descritivo" v-html="descritivoProduto">

              </p>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-6 col-sm-10 offset-md-3 offset-sm-1">
      <div class="card">
        <div class="card-header p-4">
          <h6>
            Olá! Eu sou o {{ nameConsultor }}, o Corretor Digital da Facilita Corretora de Seguros LTDA e
            vou te ajudar na Cotação e Contratação do seu {{ nameProduto }}.
          </h6>

        </div>
        <div class="card-body">
          <div class="row px-4 pb-5">
            <input-form type="string" class-list="col-6 form-edit" name="nome" placeholder="Nome"
                        label=""></input-form>
            <input-form type="string" class-list="col-6 form-edit" name="cnpj_cpf" placeholder="CPF/CNPJ"
                        label=""></input-form>
            <input-form type="string" class-list="col-6 form-edit" name="telefone" placeholder="Telefone"
                        label=""></input-form>
            <input-form type="string" class-list="col-6 form-edit" name="celular" placeholder="Celular"
                        label=""></input-form>
            <input-form type="string" class-list="col-6 form-edit" name="email" placeholder="Email"
                        label=""></input-form>
            <input-form type="string" class-list="col-6 form-edit" name="profissao" placeholder="Profissão"
                        label=""></input-form>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-6 col-sm-10 offset-md-3 offset-sm-1">
      <div class="card">
        <div class="card-header p-4">
          <h2>
            Questionário
          </h2>

        </div>
        <div class="card-body">
          <div v-for="pergunta in perguntas" v-bind:key="pergunta.id" class="row">
            <div class="col-12">
              <strong>{{ pergunta.ordem }}-{{ pergunta.enunciado }}</strong>
            </div>
            <div v-if="pergunta.tipo_resposta=='multipla'" class="col-12">
              <div v-for=" (opcao,i) in pergunta.opcoes" :key="opcao">
                <label>
                  {{ opcao }}
                  <input :data-id="pergunta.id" :data-required="pergunta.obrigatoria" type="checkbox"
                         :name="'resposta['+pergunta.id+']'"
                         :class="'resposta['+pergunta.id+']'" class="form-check" :value="i+'-'+opcao">
                </label>
              </div>
            </div>
            <div v-if="pergunta.tipo_resposta=='unica'" class="col-12">
              <div v-for=" (opcao,i) in pergunta.opcoes" :key="opcao">
                <label>
                  {{ opcao }}
                  <input type="radio" :data-id="pergunta.id" :name="'resposta['+pergunta.id+']'"
                         class="form-check" :value="i+'-'+opcao">
                </label>
              </div>
            </div>
            <div v-if="pergunta.tipo_resposta=='descritiva'" class="col-12">
              <input-form :data-id="pergunta.id" type="text"
                          :name="'resposta['+pergunta.id+']'"></input-form>
            </div>
            <div v-if="pergunta.tipo_resposta=='inteira'" class="col-12">
              <input-form :data-id="pergunta.id" type="int"
                          :name="'resposta['+pergunta.id+']'"></input-form>
            </div>
            <div v-if="pergunta.tipo_resposta=='decimal'" class="col-12">
              <input-form :data-id="pergunta.id" type="decimal"
                          :name="'resposta['+pergunta.id+']'"></input-form>
            </div>
            <div v-if="pergunta.tipo_resposta=='datada'" class="col-12">
              <input-form :data-id="pergunta.id" type="date"
                          :name="'resposta['+pergunta.id+']'"></input-form>
            </div>
          </div>
          <div class="row">
            <div class="col-6 p-4">
              <button id="sendForm" class="btn btn-success btn-lg" @click="sendForm">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import axios from "axios";
import InputForm from "@/components/form/inputForm.vue";
import toastr from "toastr/build/toastr.min";
import Swal from "sweetalert2";
import Helpers from "@/services/Helpers";

export default {
  name: "FormCaptations",
  components: {InputForm},
  data() {
    return {
      nameConsultor: '-',
      nameProduto: '-',
      descritivoProduto: '',
      perguntas: null,
      servicoId: null,
      userId: null
    }
  },
  methods: {
    async createForm() {


      let params = JSON.parse(atob(this.$route.params.id));
      console.log(params)
      this.userId = params[0].user_id;
      this.servicoId = params[0].servico_id

      let id = params[0].user_id;
      axios.get(process.env.VUE_APP_API_HOST_NAME + '/api/consultor/' + id).then((response) => {
        this.nameConsultor = response.data.name
        id = params[0].servico_id
        axios.get(process.env.VUE_APP_API_HOST_NAME + '/api/servicos/' + id + '/view').then((response) => {
          this.nameProduto = response.data.nome
          let descritivo = !new Helpers().empty(response.data.descritivo)?response.data.descritivo:'';
          this.descritivoProduto = this.formatText(descritivo)
          id = params[0].servico_id
          axios.get(process.env.VUE_APP_API_HOST_NAME + '/api/perguntas/' + id + '/view').then((response) => {
            this.perguntas = response.data
          })
        })
      });

    },
    sendForm() {
      document.getElementById('sendForm').disabled = true;
      let lead = this.validateFormLead();
      if (lead === false) {
        document.getElementById('sendForm').disabled = false;
        return false;
      }

      let perguntas = this.validatePerguntas()
      if (perguntas === false) {
        document.getElementById('sendForm').disabled = false;
        return false;
      }

      axios.post(process.env.VUE_APP_API_HOST_NAME + '/api/cotations/store', {
        'lead': lead,
        'perguntas': perguntas,
        'user_id': this.userId,
        'servico_id': this.servicoId
      }).then(function (response) {
        if (response.data.success) {
          Swal.fire(
              'Muito bem 😁',
              'Aguarde enquanto entramos em contato com você!',
              'success',
          ).then(() => {
            history.back();
          })
        }
      })


    },
    validateFormLead() {
      let helper = new Helpers();
      let dataRequest = [];
      let elementInput = document.getElementById('nome').value;
      if (elementInput.length > 0) {
        dataRequest.push(elementInput);

      } else {
        toastr.info('Nome não preenchido');
        return false;
      }
      elementInput = document.getElementById('cnpj_cpf').value;
      if (elementInput.length > 0) {
        dataRequest.push(helper.unmaskNumber(elementInput));
      } else {
        toastr.info('CNPJ/CPF não preenchido')
        return false;
      }
      elementInput = document.getElementById('telefone').value;
      if (elementInput.length > 0) {
        dataRequest.push(helper.unmaskNumber(elementInput));
      } else {
        toastr.info('Telefone não preenchido')
        return false;
      }
      elementInput = document.getElementById('celular').value;
      if (elementInput.length > 0) {
        dataRequest.push(helper.unmaskNumber(elementInput));
      } else {
        toastr.info('Celular não preenchido')
        return false;
      }
      elementInput = document.getElementById('email').value;
      if (elementInput.length > 0) {
        dataRequest.push(elementInput);
      } else {
        toastr.info('Email não preenchido')
        return false;
      }
      elementInput = document.getElementById('profissao').value;
      if (elementInput.length > 0) {
        dataRequest.push(elementInput);
      } else {
        toastr.info('Profissão não preenchido')
        return false;
      }

      return dataRequest;
    },
    validatePerguntas() {
      var dataQuestionResposta = [];
      var idsAll = [];

      for (let i in this.perguntas) {
        let inputs = document.getElementsByName('resposta[' + this.perguntas[i].id + ']');
        idsAll.push(this.perguntas[i].id);
        let notNullable = false;

        for (let j = 0; j < inputs.length; j++) {

          if (inputs[j]?.type && inputs[j]?.value) {

            if (inputs[j].type == 'radio') {
              if (inputs[j].checked) {
                notNullable = true
                dataQuestionResposta.push([this.perguntas[i].id, inputs[j].value]);

              }
            } else if (inputs[j].type == 'checkbox') {

              if (inputs[j].checked) {
                notNullable = true
                dataQuestionResposta.push([this.perguntas[i].id, inputs[j].value]);
              }

            } else {
              notNullable = true
              dataQuestionResposta.push([this.perguntas[i].id, inputs[j].value]);
            }

            if ((notNullable === false && (j + 1) == inputs.length) && this.perguntas[i].obrigatoria) {
              toastr.error('A questão ' + this.perguntas[i].ordem + ' não foi preenchida')
              return false;
            }
          }
        }


      }
      return dataQuestionResposta;
    },
    formatText(text) {
      // Substitui URLs por links
      let urlPattern = /http:\/\/[^\s]+/g;
      text = text.replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`);
       urlPattern = /https:\/\/[^\s]+/g;
      text = text.replace(urlPattern, (url) => `<a href="${url}" target="_blank">${url}</a>`);
      // Substitui formatações usando tags
      text = text.replace(/\*(.*?)\*/g, '<strong>$1</strong>'); // Negrito
      text = text.replace(/_(.*?)_/g, '<em>$1</em>'); // Itálico
      text = text.replace(/~(.*?)~/g, '<del>$1</del>'); // Sublinhado
      text = text.replace(/`(.*?)`/g, '<code>$1</code>'); // Fonte monospace

      return text;
    }

  },
  mounted() {
    this.createForm()

  }
}
</script>

<style scoped>
.header-page {
  border-start-end-radius: 0px;
  border-top-left-radius: 0px;
  top: -1px;
  border: white;
}

.card {
  border: white;
}


.name-consultor {
  font-weight: 200;
}

.card-header {
  background: transparent linear-gradient(270deg, #00a089, #a2d48d) 0 0 no-repeat padding-box;
  color: white;
  font-weight: bold;
}
.descritivo{
  font-family: unset;
}

@media screen and (max-width: 800px) {
  .name-consultor, .name-produto {
    font-size: 100%;
  }
}
</style>