<template>
  <input-form class-list="col-6" type="date" label="Data Inicial " value="" name="data_inicial"/>
  <input-form class-list="col-6" type="date" label="Data Final " value="" name="data_final"/>
  <input-form class-list="col-6" type="date" label="Vencimento " value="" name="data_vencimento"/>
  <input-form class-list="col-6" type="select"
              :items="[

                  {id:'franqueador',message:'Franqueador',},
                  {id:'vendedor',message:'Vendedor',},
                  {id:'seguradora',message:'Seguradora',}
                  ]"
              label="Tipo " value="" name="type_user"/>
</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormFinanceirosComissaosUsers",
  components: {InputForm},
  data() {
    return {financeiros_comissaos_users: null}
  },
  methods: {},
  mounted() {
    setTimeout(function (){
      let dataAtual = new Date();
      let mesAtual = dataAtual.getMonth();
      let mesAnterior = mesAtual;
      let proximoMes = mesAtual+1;
      let anoAtual = dataAtual.getFullYear();
      if(new String(mesAnterior).length==1){
        mesAnterior = '0'+mesAnterior
      }
      if(new String(proximoMes).length==1){
        proximoMes = '0'+proximoMes
      }
      let ultimoDiaMes =  new Date(anoAtual,mesAnterior, 0).getDate();

      const dataInicial = anoAtual+'-'+mesAnterior+'-'+'01';

      const dataFinal = anoAtual+'-'+mesAnterior+'-'+ultimoDiaMes;
      const vencimento = anoAtual+'-'+proximoMes+'-'+'15';

      document.getElementById('data_inicial').value = dataInicial;
      document.getElementById('data_final').value = dataFinal;
      document.getElementById('data_vencimento').value = vencimento;
      document.getElementById('type_user').value='seguradora'
    },1000)

  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>