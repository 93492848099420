<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Gerar Comissão</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="/financeiros/index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormFinanceirosComissaosUsers></FormFinanceirosComissaosUsers>
        <div class="col-4">
          <button class="btn btn-system mt-4" type="button" @click="sendForm">Gerar</button>
        </div>
      </div>
    </div>
  </layout-page>
</template>
<script>
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import FormFinanceirosComissaosUsers from "@/views/financeiros_comissaos_users/FormFinanceirosComissaosUsers.vue";
import RequestHelper from "@/services/RequestHelper";
import toastr from "toastr/build/toastr.min";

export default {
  name: "CreateFinanceirosComissaosUsers",
  components: {FormFinanceirosComissaosUsers, LayoutPage, ButtonWidget},
  methods: {
    async sendForm() {
      let dataForm = {
        data_inicial: document.getElementById('data_inicial').value,
        data_final: document.getElementById('data_final').value,
        data_vencimento: document.getElementById('data_vencimento').value,
        type_user: document.getElementById('type_user').value,


      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros_comissaos_users', dataForm);
      if (response.data?.success) {
        toastr.success('Comissão Gerada')
        setTimeout(function (){
          location.href = '/financeiros/index';
        },3000)

      } else {
        if (response.response.data?.message) {
          console.log(response)
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  }
}
</script>
<style scoped>
</style>