<template>
       <input-form class-list="col-12"  type="blob" label="Anexo " value="" name="anexo"/>
<input-form class-list="col-12"  type="string" label="Nome " value="" name="nome"/>
<input-form class-list="col-12"  type="text" label="Descritivo " value="" name="descritivo"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";
import RequestHelper from "@/services/RequestHelper";


export default {
    name: "FormDocumentosAvulsosVendas",
    components: {InputForm},
    data() {
        return {documentos_avulsos_vendas: null}
    },
    methods:{
         async getDocumentosAvulsosVendas(){
            let request =  new RequestHelper();
            this.documentos_avulsos_vendas = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/documentos_avulsos_vendas/list',{});
             this.documentos_avulsos_vendas =  await this.documentos_avulsos_vendas.data;

        },


    },
    created() {
        this.getDocumentosAvulsosVendas();
    }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>