import Helpers from "@/services/Helpers";
import RequestHelper from "@/services/RequestHelper";

export default class Middleware {

    beginWaitPage(){
        document.getElementsByClassName('wait-page')[0].classList.remove('d-none');

    }
    finishWaitPage(){
        document.getElementsByClassName('wait-page')[0].classList.add('d-none');
        setTimeout(()=>{
            document.getElementsByClassName('wait-page')[0].classList.add('d-none');
        },1000);

    }
    validateHash(to) {
        let helper = new Helpers();
        if (to.meta?.auth && helper.empty(localStorage.getItem("HASH"))) {
            location.href = "/login";
        }

        if (to.name == "LoginForm" && !helper.empty(localStorage.getItem("HASH"))) {
            location.href = '/painel'
        }
    }

    routeExists(to) {
        if (to.matched.length == 0) {
            location.href = '/404'
        }
    }

    logout(to) {
        if (to.href == '/logout') {
            localStorage.setItem('HASH', undefined);
            location.href = '/login'
            return true;
        }
        return false;

    }

    finishLoading() {
        window.addEventListener("load", function() {
            document.getElementsByClassName('loading-page')[0].classList.add('d-none');
        });

    }

    beginLoading() {
        document.getElementsByClassName('loading-page')[0].classList.remove('d-none');

    }

    endLoading() {
        document.getElementsByClassName('loading-page')[0].classList.add('d-none');
    }



    setRegisterLastRouteBeforeLogin() {
        if (location.pathname !== "/login" && location.pathname !== "/logout") {
            localStorage.setItem('href', location.href);
            console.log(localStorage.getItem('href'))
        }
    }

    getRegisterLastRouteBeforeLogin() {
        return localStorage.getItem('href');
    }

    async userPermissions(to) {


        if (to.href !== '/login' && (to.href.indexOf('/cotations/') == -1 || to.href == '/cotations/index')) {
            setTimeout(async () => {
                let request = new RequestHelper();
                let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/auth/me', {view: true});
                console.log(response.data.type)

                if (response?.data) {
                    let styleTag = document.createElement("style");
                    document.head.appendChild(styleTag);
                    if (response?.data?.type == 'super_admin') {

                        styleTag.innerHTML = '.super_admin_d_none {display:none;}';
                    }
                    if (response?.data?.type == 'admin') {

                        styleTag.innerHTML = '.admin_d_none {display:none;}';
                    }

                    if (response?.data?.type == 'revendedor') {
                        styleTag.innerHTML = '.revendedor_d_none {display:none;}';

                    }
                    if (response?.data?.type == 'vendedor') {
                        styleTag.innerHTML = '.vendedor_d_none {display:none;}';
                    }
                    if (response?.data?.type == 'franqueador') {
                        styleTag.innerHTML = '.franqueador_d_none {display:none;}';
                    }

                    if(to?.meta?.authorize && response?.data?.type){
                        if (to.meta.authorize.indexOf(response.data.type)==-1){
                            location.href = '/404'
                        }
                    }


                }
            }, 100)

        }


    }
    async uniqueConfiguration(to) {
        if (to.href == '/business/create') {
            let request = new RequestHelper();
            let resposeRequest = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/auth/me', {view: true});
            let business_id = await resposeRequest.data.business_id;
            let helper = new Helpers();
            if (!helper.empty(business_id)) {
                location.href = '/business/' + business_id + '/edit';
            }
        }
    }


    requiredLogin(to) {
        if (to.meta.auth){
            return true;
        }
        return false;
    }
}
