<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Financeiros</h5>
          </div>
          <div class="float-end">
            <button-widget cor="azul" href="../index" tamanho="M">
              Voltar
            </button-widget>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormFinanceiros></FormFinanceiros>
        <button class="btn btn-system mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>

</template>

<script>
import FormFinanceiros from "@/views/financeiros/FormFinanceiros.vue";
import RequestHelper from "@/services/RequestHelper";
import ButtonWidget from "@/components/widget/buttonWidget.vue";
import LayoutPage from "@/components/page/layoutPage.vue";
import toastr from "toastr/build/toastr.min";

export default {
  name: "EditFinanceiros",
  components: {LayoutPage, ButtonWidget, FormFinanceiros},
  methods: {
    async edit(id) {
      let request = new RequestHelper();
      let response = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros/' + id, {});
      document.getElementById('user_id').value = response.data.user_id;
      document.getElementById('tipo').value = response.data.tipo;
      document.getElementById('valor').value = response.data.valor;
      document.getElementById('parcela').value = response.data.parcela;
      document.getElementById('vencimento_primario').value = response.data.vencimento_primario;

    },
    async sendForm() {
      let dataForm = {
        user_id: document.getElementById('user_id').value,
        tipo: document.getElementById('tipo').value,
        valor: document.getElementById('valor').value,
        parcela: document.getElementById('parcela').value,
        vencimento_primario: document.getElementById('vencimento_primario').value,

        _method: 'PUT'

      }
      if (!dataForm.parent_id) {
        delete dataForm.parent_id
      }
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros/' + this.$route.params.id, dataForm);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso')
      } else {
        if (response.response.data?.message) {
          toastr.error(response.response.data?.message);
        } else {
          toastr.error('Houve um problema ao inserir');
        }

      }
    }
  },
  created() {
    this.edit(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>