<template>


  <div class="row">
    <div class=" col-12 col-md-4 p-2">
      <div class="float-start">
        <h5> Perguntas</h5>

      </div>
    </div>
    <div class=" col-12 col-md-4 p-2">

      <div class="float-start">
        <button type="button" class="btn btn-system mx-2" @click="perguntasObrigatorias()">
          Todas perguntas como obrigatórias
        </button>
      </div>

    </div>
    <div class="col-12 col-md-4 p-2">
      <div class="float-end">
        <button type="button" class="btn btn-system" @click="modalAdd">
          Adicionar
        </button>
      </div>
    </div>
    <div class="col-12 p-4 p-2">
      <div>
        <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
               type="text" v-model="search">
      </div>
    </div>

  </div>

  <div class="table-responsive">
    <table class="table">
      <thead>

      <tr>
        <th>Ações</th>
        <th>#</th>
        <th>Enunciado</th>
        <th>Ordem</th>
        <th>Tipo de Resposta</th>
        <th>Obrigatória</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in rows" :key="row.id">
        <td>
          <div class="btn-group btn-sm" role="group" aria-label="Basic example">
            <router-link class="btn btn-system" :to="'/perguntas/'+row.id+'/edit'">
              <i class="bi bi-pencil-square"></i>
            </router-link>
            <button class="btn btn-system" @click="deleteRow(row.id)">
              <i class="bi bi-trash2-fill"></i>
            </button>
          </div>

        </td>
        <td>{{ row.id }}</td>
        <td>{{ row.enunciado }}</td>
        <td>{{ row.ordem }}</td>
        <td>{{ row.tipo_resposta }}</td>
        <td>
          <label class="toggle">
            <input checked v-if="row.obrigatoria" @change="mudarObrigatoriedadePergunta(row.id)" type="checkbox">
            <input  v-if="!row.obrigatoria" @change="mudarObrigatoriedadePergunta(row.id)" type="checkbox">
            <span class="slider"></span>
          </label>
        </td>


      </tr>
      <tr v-if="rows==null && loading == false">
        <td colspan="10" class="text-center"> Não há dados</td>
      </tr>
      <tr v-if="loading == true">

        <td colspan="10">
          <div class="row">
            <div class="col-12 d-flex justify-content-center">
              <div class="loader"></div>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <ModalWidget id="cadastrar">
    <create-perguntas>
    </create-perguntas>
  </ModalWidget>

</template>
<script>

import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";
import ModalWidget from "@/components/widget/modalWidget.vue";
import CreatePerguntas from "@/views/perguntas/CreatePerguntas.vue";

export default {
  name: "IndexPerguntas",
  components: {CreatePerguntas, ModalWidget},
  data() {
    return {
      rows: null,
      search: null,
      loading: true
    }
  },
  methods: {
    async list() {

      this.loading = true;
      let dataRequest = {
        entrevista_id: this.$route.params.id,

      };
      let requestHelper = new RequestHelper();
      let helpers = new Helpers();

      if (!helpers.empty(this.search)) {
        dataRequest = {
          search: this.search,
          entrevista_id: this.$route.params.id
        };
      }

      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/perguntas', dataRequest);

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      }

      this.loading = false;
    },
    async deleteRow(id) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/perguntas/' + id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    modalAdd() {
      document.getElementById('cadastrar').classList.remove('d-none')
    },
    async perguntasObrigatorias() {
      let entrevista_id = this.$route.params.id;
      let requestHelper = new RequestHelper();
      let dataRequest = {
        _method: 'PUT',
        obrigatoria: true
      };
      let response = await requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/entrevista/obrigatoria/' + entrevista_id, dataRequest)
      if (response.data.success) {
        toastr.success('Salvo com sucesso!');
      }
      this.list();
    },
    async mudarObrigatoriedadePergunta(id) {
      console.log(id)
      let requestHelper = new RequestHelper();
      await requestHelper.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/perguntas/obrigatoria/' + id, {_method:'PUT'})
      this.list();
    }

  },
  created() {
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>

<style scoped>
.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #212529;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
</style>