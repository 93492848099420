<template>

  <div class=" p-5">
    <div class="row justify-content-center">
      <div class="col-6">
        <div class="card">
          <div class="card-header pt-5 pb-4">
            <div>
              <img width="150" src="@/assets/logo.png">
              <h3>Fazer cadastro</h3>

            </div>


          </div>
          <div class="card-body">


            <div class="mb-3">
              <label class="m-2">
                Email
              </label>
              <input v-model="email" type="text" id="email" class="form-control" placeholder="Digite Email"
                     aria-label="Username">
            </div>

          </div>
          <div class="card-footer pt-3 pb-5">
            <div class="row">
              <div class="col">
                <button @click="redefinirSenha" class="btn btn-lg btn-system btn-loading">
                  Redefinir senha
                </button>

                <router-link to="login" class="btn btn-lg btn-system btn-loading m-2 float-sm-end">
                  Voltar
                </router-link>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>

  </div>

</template>


<script>

import axios from "axios";
import toastr from "toastr";

export default {
  name: "ForgetPasswordForm",
  data() {
    return {
      email: ''
    }
  },
  methods: {
    redefinirSenha() {
      axios.post(process.env.VUE_APP_API_HOST_NAME +'/api/redefinir-senha', {email: this.email})
          .then((response) => {
            if(response.data.success){
              toastr.success('A nova senha foi enviada para seu e-mail.')
            }
          });
    }
  }
}
</script>

<style>
@import "toastr/build/toastr.css";

#app {
  background: #3baaa1;
}

html {
  background: #3baaa1;
}
</style>