<template>
  <input-form    placeholder="Selecione User " class-list="col-12" type="select2" :url="url" label="User " value=""
              name="user_id"/>
  <input-form class-list="col-12" :items="[{id:'receber',message:'Receber'},{id:'pagar',message:'Pagar'}]" type="select" label="Tipo " value="" name="tipo"/>
  <input-form class-list="col-12" type="decimal" label="Valor " value="" name="valor"/>
  <input-form class-list="col-12" type="int" label="Parcela " value="" name="parcela"/>
  <input-form class-list="col-12" type="date" label="Vencimento Primario " value="" name="vencimento_primario"/>

</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormFinanceiros",
  components: {InputForm},
  data() {
    return {url: null}
  },
  methods: {



  },
  created() {
    this.url = '/api/users/list' ;
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>