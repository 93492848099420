<template>

  <table class="table table-borderless">
    <tbody>
    <tr class="border-top" v-for="row in rows" :key="row.id">

      <td v-if="row.lado=='esquerdo'">
        <span class="badge text-bg-primary float-start">{{ row.emissor }}</span>
        <div class="balao-direita">
          {{ row.mensagem }}
        </div>

      </td>
      <td v-if="row.lado=='direito'">
        <span class="badge text-bg-primary float-end">{{ row.emissor }}</span>

        <div class="balao-esquerda">
          {{ row.mensagem }}
        </div>
      </td>

    </tr>
    <tr v-if="rows==null">
      <td colspan="3" class="text-center"> Não há mensagem</td>
    </tr>
    </tbody>
  </table>

</template>
<script>


import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";

export default {
  name: "IndexChatVendas",
  components: {},
  props: {
    venda_id: String
  },
  data() {
    return {
      rows: null,
      search: null
    }
  },
  methods: {
    async list() {


      let dataRequest = {
        venda_id: this.venda_id
      };
      let requestHelper = new RequestHelper();
      let helpers = new Helpers();

      if (!helpers.empty(this.search)) {
        dataRequest = {
          search: this.search,
          venda_id: this.venda_id
        };
      }

      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/chat_vendas', dataRequest);

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      }


    },
    async deleteRow(id) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/chat_vendas/' + id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    }

  },
  created() {
    this.list();
    let urlLink = location.href;
    console.log(urlLink)
    let intervalModalChat = setInterval(() => {
      if (urlLink == location.href) {
        this.list();
      } else {
        clearInterval(intervalModalChat)
      }
    }, 5000);

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>
<style>

.balao-direita {
  background-color: #0074D9; /* Cor de fundo do balão */
  color: #fff; /* Cor do texto no balão */
  border-radius: 10px; /* Borda arredondada */
  padding: 4px; /* Espaçamento interno */

  float: right; /* Alinhar à direita */
  clear: both; /* Evitar que outros elementos flutuem ao lado */
  max-width: 130px; /* Largura máxima do balão */
  font-size: 12px;
}

.balao-esquerda {
  background-color: #E5E5EA; /* Cor de fundo do balão */
  color: #000; /* Cor do texto no balão */
  border-radius: 10px; /* Borda arredondada */
  padding: 4px; /* Espaçamento interno */

  float: left; /* Alinhar à esquerda */
  clear: both; /* Evitar que outros elementos flutuem ao lado */
  max-width: 130px; /* Largura máxima do balão */
  font-size: 12px;
}
</style>