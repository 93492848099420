<template>
  <input-form class-list="col-12" type="blob" label="Cotação " value="" name="anexo"/>
  <input-form class-list="col-4" type="string" label="Nome " value="" name="nome"/>
  <input-form class-list="col-4" type="decimal" label="Valor do Premio " value="" name="valor_premio"/>
  <input-form class-list="col-4" type="decimal" label="Comissão Sobre o Premio %" value="" name="comissao"/>
  <input-form class-list="col-4" type="decimal" label="Comissão da Corretora %" value="" name="porcentagem_corretora"/>
  <input-form class-list="col-4" type="decimal" label="Comissão da Franqueadora % " value=""
              name="porcentagem_franquiadora"/>
  <input-form class-list="col-4" type="decimal" label="Comissão do Vendedor %" value="" name="porcentagem_vendedor"/>
  <input-form class-list="col-4" type="decimal" label="Desconto Maximo" value="" name="desconto"/>
  <input-form class-list="col-4" type="tinyint" label="Indicar como melhor opção " value="" name="melhor_opcao"/>
  <input-form class-list="col-12" type="text" label="Descritivo " value="" name="descritivo"/>
  <input-form class-list="col-12" type="select2" url="/api/anexos_vendas/listSeguradora"
              label="Seguradora" value="" name="seguradora_user_id"/>


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";


export default {
  name: "FormAnexosVendas",
  components: {InputForm},
  data() {
    return {anexos_vendas: null}
  },
  methods: {},
  created() {

  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>