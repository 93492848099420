<template>
  <input-form class-list="col-12" type="decimal" label="Valor " value="" name="valor"/>
  <input-form class-list="col-12" type="decimal" label="Desconto " value="" name="desconto"/>
  <input-form class-list="col-12" :items="[{id:'dinheiro',message:'Dinheiro'},{id:'cheque',message:'Cheque'},{id:'pix',message:'PIX'},{id:'cartao',message:'Cartão'},]" type="select" label="Titulo " value="" name="titulo"/>


</template>

<script>
import InputForm from "@/components/form/inputForm.vue";
import RequestHelper from "@/services/RequestHelper";


export default {
  name: "FormFinanceirosPagamentos",
  components: {InputForm},
  data() {
    return {financeiros_pagamentos: null}
  },
  methods: {
    async getFinanceirosPagamentos() {
      let request = new RequestHelper();
      this.financeiros_pagamentos = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/financeiros_pagamentos/list', {});
      this.financeiros_pagamentos = await this.financeiros_pagamentos.data;

    },


  },
  created() {
    this.getFinanceirosPagamentos();
  }
}
</script>

<style scoped>
@import "toastr/build/toastr.css";
</style>