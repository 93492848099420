<template>

  <div class="row">
    <div class="col-12 ps-4 pt-3 ">
      <div class="float-start">
        <h5> Documentos Anexados</h5>
      </div>
      <div class="w-50">
        <input id="search" class="form-control" @change="list()" placeholder="Digite sua pesquisa"
               type="text" v-model="search">
      </div>

      <div class="float-end">
        <button-widget cor="azul" :href="'/documentos_avulsos_vendas/create/'+this.venda_id" tamanho="M">
          Adicionar
        </button-widget>
      </div>
    </div>

    <div class="col-12">

      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>Anexo</th>
          <th>Nome</th>

          <th>Ações</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows" :key="row.id">
          <td>{{ row.id }}</td>
          <td>
            <button class="btn btn-success" @click="download(row.anexo,row.nome)">
              <i class="bi bi-cloud-arrow-down"></i>
            </button>

          </td>
          <td>{{ row.nome }}</td>


          <td>
            <div class="btn-group btn-sm" role="group" aria-label="Basic example">
              <router-link class="btn btn-system" :to="'/documentos_avulsos_vendas/'+row.id+'/edit'">
                <i class="bi bi-pencil-square"></i>
              </router-link>
              <button class="btn btn-system" @click="deleteRow(row.id)">
                <i class="bi bi-trash2-fill"></i>
              </button>
            </div>

          </td>
        </tr>
        <tr v-if="rows==null">
          <td colspan="4" class="text-center"> Não há dados</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import ButtonWidget from "@/components/widget/buttonWidget.vue";
import RequestHelper from "@/services/RequestHelper";
import Helpers from "@/services/Helpers";
import toastr from "toastr/build/toastr.min";

export default {
  props: {
    venda_id: String
  },
  name: "IndexDocumentosAvulsosVendas",
  components: {ButtonWidget},
  data() {
    return {
      rows: null,
      search: null
    }
  },
  methods: {
    async list() {


      let dataRequest = {
        venda_id: this.venda_id
      };
      let requestHelper = new RequestHelper();
      let helpers = new Helpers();

      if (!helpers.empty(this.search)) {
        dataRequest = {
          venda_id: this.venda_id,
          search: this.search
        };
      }

      let dataRow = await requestHelper.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/documentos_avulsos_vendas', dataRequest);

      if (dataRow.data.data.length > 0) {
        this.rows = dataRow.data.data;

      } else if (!helpers.empty(dataRow.response?.data)) {
        toastr.error('Houve um problema');
      }


    },
    async deleteRow(id) {
      let requestHelper = new RequestHelper();
      let dataRow = await requestHelper.deleteAuth(process.env.VUE_APP_API_HOST_NAME + '/api/documentos_avulsos_vendas/' + id);
      if (dataRow.data.success) {
        this.list();
        toastr.success('Apagado com sucesso');
      } else {
        toastr.error('Houve um problema ao apagar');
      }
    },
    download(base64Data, fileName) {
      // Obter o tipo MIME a partir dos dados Base64
      const mimeType = base64Data.split(':')[1].split(';')[0];

      // Decodificar a string Base64 em um array de bytes
      const byteCharacters = atob(base64Data.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Criar um objeto Blob com os dados decodificados e o tipo MIME
      const blob = new Blob([byteArray], {type: mimeType});

      // Obter a extensão do arquivo a partir do tipo MIME
      const fileExtension = mimeType.split('/')[1];


      // Definir o nome de arquivo como o nome fornecido ou o nome padrão
      const finalFileName = fileName + '.' + fileExtension

      // Criar um URL do objeto Blob
      const blobUrl = URL.createObjectURL(blob);

      // Criar um link de download e acionar o clique nele para iniciar o download
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = finalFileName;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();

      // Remover o link após o download
      document.body.removeChild(a);

      // Liberar o URL do objeto Blob
      URL.revokeObjectURL(blobUrl);
    }

  },
  created() {
    this.list();

  }
}

</script>

<style scoped>
@import "toastr/build/toastr.css";
@import "bootstrap-icons/font/bootstrap-icons.min.css";
</style>