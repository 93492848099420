<template>
  <layout-page>
    <div class="card-header">
      <div class="row">
        <div class="col-12 ps-4 pt-3 ">
          <div class="float-start">
            <h5>Editar Cotações</h5>
          </div>
          <div class="float-end">
            <button class="btn btn-system" @click="goBack">
              Voltar
            </button>
          </div>
        </div>

      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <FormAnexosVendas></FormAnexosVendas>
        <button class="btn btn-system mt-4" type="button" @click="sendForm">Salvar</button>
      </div>
    </div>
  </layout-page>
</template>
<script>

import LayoutPage from "@/components/page/layoutPage.vue";
import FormAnexosVendas from "@/views/anexos_vendas/FormAnexosVendas.vue";
import RequestHelper from "@/services/RequestHelper";
import toastr from "toastr/build/toastr.min";


export default {
  name: "EditAnexosVendas",
  components: {FormAnexosVendas, LayoutPage},
  data(){
    return {
      anexo_venda_id: null
    }
  },
  methods: {
    async edit(id) {
      let request = new RequestHelper();
      let response = await request.getAuth(process.env.VUE_APP_API_HOST_NAME + '/api/anexos_vendas/' + id, {});
      document.getElementById('nome').value = response.data.nome;
      document.getElementById('valor_premio').value = response.data.valor_premio;
      document.getElementById('anexo').dataset.value = response.data.anexo;
      document.getElementById('comissao').value = response.data.comissao;
      document.getElementById('porcentagem_corretora').value = response.data.porcentagem_corretora;
      document.getElementById('porcentagem_franquiadora').value = response.data.porcentagem_franquiadora;
      document.getElementById('porcentagem_vendedor').value = response.data.porcentagem_vendedor;
      document.getElementById('melhor_opcao').value = response.data.melhor_opcao;
      document.getElementById('desconto').value = response.data.desconto;
      document.getElementById('descritivo').value = response.data.descritivo;
      document.getElementById('seguradora_user_id').value = response.data.seguradora_user_id;
      this.anexo_venda_id = id;


    },
    async sendForm() {
      let dataForm = {
        _method: 'PUT',
        nome: document.getElementById('nome').value,
        anexo: document.getElementById('anexo').dataset.value,
        valor_premio: document.getElementById('valor_premio').value,
        comissao: document.getElementById('comissao').value,
        porcentagem_corretora: document.getElementById('porcentagem_corretora').value,
        porcentagem_franquiadora: document.getElementById('porcentagem_franquiadora').value,
        porcentagem_vendedor: document.getElementById('porcentagem_vendedor').value,
        melhor_opcao: document.getElementById('melhor_opcao').value,
        desconto: document.getElementById('desconto').value,
        descritivo: document.getElementById('descritivo').value,
        seguradora_user_id: document.getElementById('seguradora_user_id').value,


      }
      console.log(dataForm)
      let request = new RequestHelper();
      let response = await request.postAuth(process.env.VUE_APP_API_HOST_NAME + '/api/anexos_vendas/'+this.anexo_venda_id, dataForm);
      if (response.data?.id) {
        toastr.success('Salvo com sucesso!');
      } else {
        toastr.error('Houve um problema ao inserir');
      }
    },
    goBack() {
      history.back();
    }
  },
  mounted() {
    this.edit(this.$route.params.id)
  }
}
</script>
<style scoped>
</style>